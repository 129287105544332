import React, { useEffect, useRef } from 'react'
import { Box } from '@mui/material'


const TrustBox = () => {

  const ref = useRef(null)

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <Box
      ref={ref}
      sx={{ width: 'fit-content', maxWidth: '255px' }}
      data-locale='fr-FR'
      data-template-id={process.env.REACT_APP_TRUSTBOX_TEMPLATE_ID}
      data-businessunit-id={process.env.REACT_APP_TRUSTBOX_BUSINESSUNIT_ID}
      data-style-height='150px'
      data-style-width='100%'
      data-theme='light'
      data-font-family='Raleway'
    >
      <a href='https://fr.trustpilot.com/review/www.wake-up.io' target='_blank' rel='noopener noreferrer'>Trustpilot</a>
    </Box>
  )
}

export default TrustBox
