import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import avatarTeamMember from '../../assets/DIVERS/avatar_team_member.svg'


export const AvatarGraph = ({ photoUrl, style, onClick, firstname, className, size = 60, noShadow, sx }) => {
  
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.up('sm'))
  
  const diameter = isDownSm ? size * 2 / 3 : size
  const displayFirstname = size >= 30
  
  return (
    <Tooltip title={firstname || 'undefined'} disableHoverListener={displayFirstname}>
      <Box className={className} style={style} sx={{ position: 'absolute', cursor: 'pointer', ...sx }}>
        {photoUrl
          ? <Box
            onClick={onClick}
            sx={{
              overflow: 'hidden',
              border: 'solid white',
              backgroundSize: 'cover',
              m: 'auto',
              backgroundImage: `url('${photoUrl}')`,
              height: diameter,
              width: diameter,
              borderRadius: diameter / 2,
              borderWidth: diameter / 15,
              boxShadow: noShadow ? 'unset' : 'inset',
            }}
          />
          : <Box
            onClick={onClick}
            sx={{
              overflow: 'hidden',
              backgroundSize: 'cover',
              m: 'auto',
              backgroundImage: `url('${avatarTeamMember}')`,
              height: diameter,
              width: diameter,
              borderRadius: diameter / 2,
              borderWidth: diameter / 15,
              boxShadow: noShadow ? 'unset' : 'inset',
            }}
          />
        }
        {firstname && displayFirstname && <Box sx={{
          backgroundColor: 'white',
          color: 'black',
          fontWeight: 700,
          textAlign: 'center',
          borderRadius: 5,
          m: '-3px auto',
          lineHeight: '1.5em',
          width: 'fit-content',
          padding: '0 5px',
          fontSize: `${size / 5}px`,
          boxShadow: noShadow ? 'initial' : 'inherit',
        }}>{firstname}</Box>}
      </Box>
    </Tooltip>
  )
}

AvatarGraph.propTypes = {
  photoUrl: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  firstname: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  noShadow: PropTypes.bool,
  sx: PropTypes.object,
}

export default AvatarGraph
