import React from 'react'
import PropTypes from 'prop-types'
import { Typography, FormControlLabel } from '@mui/material'
import GreenCheckbox from '../_library/GreenCheckbox'


export const Questions = ({ topic, questions, selectedQuestions, teammate, selectHandler }) =>
  <>
    <Typography variant='h2' mt={2} fontSize='1.35rem' color='primary.main'>
      {topic?.text?.fr?.replace('FIRSTNAME', teammate.firstname) || 'Catégorie sans nom'}
    </Typography>
    {questions.map(question =>
      <FormControlLabel
        sx={{ mx: 0 }}
        key={question.id}
        control={<GreenCheckbox checked={selectedQuestions && selectedQuestions.some(selectedQuestion => question.id === selectedQuestion.id)} name='checkedG' />}
        onChange={event => selectHandler(event.target.checked, question)}
        label={<Typography lineHeight='1.5rem' my='.7rem'>{question?.title?.fr[teammate.gender]}</Typography>}
      />,
    )}
  </>

Questions.propTypes = {
  topic: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  selectedQuestions: PropTypes.array.isRequired,
  teammate: PropTypes.object.isRequired,
  selectHandler: PropTypes.func.isRequired,
}

export default Questions
