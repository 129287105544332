import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import background from '../../assets/ONBOARDING/background.mp4'
const PREFIX = 'ExplainScreen'

const classes = {
  formGrid: `${PREFIX}-formGrid`,
  animationContainer: `${PREFIX}-animationContainer`,
  anim: `${PREFIX}-anim`,
}

const StyledGrid = styled(Grid)((
  {
    theme,
  },
) => ({
  [`&.${classes.formGrid}`]: {
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      right: 0,
    },
  },

  [`& .${classes.animationContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  [`& .${classes.anim}`]: {
    width: '90%',
    marginTop: '3rem',
  },
}))

export const ExplainScreen = ({ animation }) => (
  <StyledGrid item xs={12} sm={5} className={classes.formGrid}>
    <video autoPlay loop='loop' muted style={{ position: 'fixed', zIndex: -1, top: 0 }}>
      <source src={background} type='video/mp4' />
        Merci de mettre à jour votre navigateur ou d&apos;essayer un autre navigateur
    </video>
    <div className={classes.animationContainer}>
      <video autoPlay loop='loop' muted className={classes.anim}>
        <source src={animation} type='video/mp4' />
          Merci de mettre à jour votre navigateur ou d&apos;essayer un autre navigateur
      </video>
    </div>
  </StyledGrid>
)

ExplainScreen.propTypes = {
  animation: PropTypes.string.isRequired,
}

export default ExplainScreen
