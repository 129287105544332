import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { getFamousPeople, getFamousSubTitle } from '../../_helpers/cardHelper'


export const FamousAvatars = ({ profileType }) => (
  <>
    <Box sx={{ float: 'right', width: { sm: '40%' } }}>
      {getFamousPeople(profileType).map(({ avatar, name, slug }) =>
        <Box component='img' src={avatar} alt={name} key={slug} sx={{ width: '33%' }} />,
      )}
    </Box>
    <Typography variant='h3' color='tertiary.dark' fontSize='1.5rem' fontWeight={800} mt={4} mb={2}>Ils ont aussi ce talent</Typography>
    <Typography variant='body1' fontWeight={200} lineHeight={2} mb={2}>
      {getFamousSubTitle(profileType)}
    </Typography>
  </>
)

FamousAvatars.propTypes = {
  profileType: PropTypes.string.isRequired,
}

export default FamousAvatars
