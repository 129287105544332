import React, { useEffect, useMemo, useState } from 'react'
import { FormControlLabel, TextField, Switch, InputLabel, MenuItem, FormControl, Select, Typography, Tooltip, Autocomplete,
  Box, Button, Link, FormHelperText } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CTAButton from '../../_library/FirstLevelButton'
import { useNavigate } from 'react-router-dom'
import { PRICING, TEAMS } from '../../../_constants/routes'
import { ACCESS_LEVEL_ADMIN, COMPANY_COLLECTION, TEAM_COLLECTION, USER_COLLECTION } from '../../../_constants/globals'
import { AddCircle as AddCircleIcon, HelpOutline } from '@mui/icons-material'
import { xssCheck } from '../../../_helpers/helpers'
import { REGEX_MAIL } from '../../../_constants/regex'
import LoadingBoussole from '../../_library/LoadingBoussole'
import useAuth from '../../../hooks/useAuth'
import { arrayUnion } from 'firebase/firestore'
import { pickBy } from 'lodash'
import { INVITE_MEMBER_ERROR, INVITE_MEMBER_SUCCESS, LOGIN_ERROR } from '../../../store/types'
import { resetNotifications } from '../../../store/actions/notificationActions'
import { httpsCallable } from 'firebase/functions'
import useFirestore from '../../../hooks/useFirestore'
import { functions } from '../../../firebase'


const InvitationForm = () => {
  
  const { t } = useTranslation()
  const profile = useAuth().getProfile()
  const dataError = useSelector(state => state.data.dataError)
  const companyHooks = useFirestore(COMPANY_COLLECTION)
  const dbCompanies = companyHooks.getDocs()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHooks.getDocs()
  const companyMates = useSelector(state => state.firestore.users)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [firstname, setFirstname] = useState(null)
  const [email, setEmail] = useState(null)
  const [isManager, setIsManager] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [companyMate, setCompanyMate] = useState(null)
  const [errors, setErrors] = useState({})
  
  const company = useMemo(() => dbCompanies ? dbCompanies[0] : null, [dbCompanies])
  
  const teams = useMemo(
    () => dbTeams?.filter(t => t.managerRefs.some(ref => ref.id === profile.id) || profile.roles?.includes('consultant') || profile.accessLevel >= ACCESS_LEVEL_ADMIN),
    [dbTeams, profile],
  )
  
  const team = useMemo(() => dbTeams?.find(t => t.id === profile.currentTeamSelectedRef?.id), [dbTeams, profile])
  
  const filteredCompanyMates = useMemo(
    () => team && companyMates?.filter(u => u.id !== profile.id && !team.memberRefs.find(ref => ref.id === u.id)),
    [companyMates, profile.id, team],
  )
  
  const displayForm = useMemo(
    () => team && profile && (team.managerRefs.find(ref => ref.id === profile.id) || profile.accessLevel >= ACCESS_LEVEL_ADMIN ) && filteredCompanyMates,
    [team, profile, filteredCompanyMates],
  )
  
  useEffect(() => {
    if (submitted && dataError) setSubmitted(false)
  }, [submitted, dataError])
  
  useEffect(() => {
    if (companyMate) {
      setFirstname(companyMate.firstname)
      setEmail(companyMate.email)
    }
  }, [companyMate, setEmail])
  
  useEffect(function onCompanyChange() { if (company?.licences > 0) setErrors(prev => ({ ...prev, licences: null }))}, [company])
  useEffect(function onTeamChange() {setErrors(prev => ({ ...prev, team: null }))}, [team])
  useEffect(function onFirstnameChange() {setErrors(prev => ({ ...prev, firstname: null }))}, [firstname])
  useEffect(function onEmailChange() {setErrors(prev => ({ ...prev, email: null }))}, [email])
  
  const handleSubmit = e => {
    e.preventDefault()
    if (!company?.licences > 0 && !companyMate) setErrors(prev => ({ ...prev, licences: 'no_invite' }))
    if (!team || !teams.some(t => t.id === team.id)) setErrors(prev => ({ ...prev, team: 'Il faut sélectionner une équipe' }))
    if (!email) setErrors(prev => ({ ...prev, email: 'Email field must be completed' }))
    if (!firstname) setErrors(prev => ({ ...prev, firstname: 'Firstname field must be completed' }))
    if (email && (!xssCheck(email) || !email.match(REGEX_MAIL))) setErrors(prev => ({ ...prev, email: 'L\'email doit être du type email@domain.ext' }))
    if (firstname && (!xssCheck(firstname))) setErrors(prev => ({ ...prev, firstname: 'Le prénom n\'est pas valide' }))
    if ((company?.licences > 0 || companyMate) && team && teams.some(t => t.id === team.id) && email && xssCheck(email) && email.match(REGEX_MAIL) && firstname && xssCheck(firstname)) {
      setSubmitted(true)
      try {
        return (new Promise((resolve, reject) => {
          if (companyMate?.id)
            teamHooks.updateDoc(profile.currentTeamSelectedRef.id, pickBy({
              managerRefs: isManager && arrayUnion(userHooks.getDocRef(companyMate.id)),
              memberRefs: arrayUnion(userHooks.getDocRef(companyMate.id)),
            })).then(resolve).catch(reject)
          else httpsCallable(functions, 'inviteUser')({
            firstname,
            email,
            companyId: dbTeams.find(t => t.id === profile.currentTeamSelectedRef.id).companyRef.id,
            teamId: profile.currentTeamSelectedRef.id,
            isManager,
          }).then(resolve).catch(reject)
        }))
          .then(() => {
            setFirstname(null)
            setEmail(null)
            setIsManager(false)
            return dispatch({ type: INVITE_MEMBER_SUCCESS })
          })
          .catch(err => {
            if (err.message === 'error.auth/email-already-exists') return dispatch({ type: LOGIN_ERROR, payload: err })
            else return dispatch({ type: INVITE_MEMBER_ERROR, err })
          })
          .finally(() => resetNotifications(dispatch))
      }
      catch (e) {console.error(e)}
      finally {setSubmitted(false)}
    }
  }
  
  const onTeamChanged = async teamId => {
    userHooks.updateDoc(profile.id, { currentTeamSelectedRef: teamHooks.getDocRef(teamId) })
  }
  
  if (submitted) return <LoadingBoussole />
  else return (
    <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 2, maxWidth: '500px' }} >
      {dataError && <Typography style={{ color: 'red' }}>{dataError.message}</Typography>}
      {profile.currentTeamSelectedRef && <Box sx={{ display: 'inline-flex', verticalAlign: 'middle' }}>
        <FormControl sx={{ pb: 3 }} variant='standard' error={Boolean(errors.team)}>
          <InputLabel id='select-team-label'>{t('common.team')}</InputLabel>
          <Select
            labelId='select-team-label'
            value={teams && profile ? profile.currentTeamSelectedRef.id : ''}
            onChange={e => onTeamChanged(e.target.value)}
          >
            {teams && Object.keys(teams).map(key => <MenuItem key={key} value={teams[key].id}>{teams[key].name}</MenuItem>)}
          </Select>
          <FormHelperText>{errors.team}</FormHelperText>
        </FormControl>
        <Button onClick={() => navigate(TEAMS)} sx={{ display: 'flex', color: 'primary.light' }}>
          <AddCircleIcon sx={{
            fill: theme => theme.palette.primary.light,
            pr: .5,
            width: '30px',
            height: '30px',
            marginLeft: 2,
            alignSelf: 'center',
          }} />&nbsp;{t('team.new')}
        </Button>
      </Box>}
      {displayForm && <>
        <Autocomplete
          id='user-select'
          options={filteredCompanyMates}
          getOptionLabel={option => option.firstname || ''}
          renderInput={params => <TextField
            {...params}
            sx={{ mb: 4 }}
            label={t('team.firstname')}
            required
            fullWidth
            error={Boolean(errors.firstname)}
            helperText={errors.firstname}
          />}
          value={companyMate || ''}
          inputValue={firstname || ''}
          onChange={(event, newValue) => setCompanyMate(newValue)}
          onInputChange={(event, value) => {
            setFirstname(value)
            setCompanyMate(null)
          }}
          freeSolo
        />
        <TextField
          sx={{ mb: 4 }}
          name='email'
          type='email'
          label={t('team.email')}
          value={email || ''}
          onChange={e => setEmail(e.target.value.toLowerCase())}
          color='secondary'
          required
          fullWidth
          disabled={Boolean(companyMate)}
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
        <FormControlLabel
          control={<Switch
            checked={isManager}
            onChange={e => setIsManager(e.target.checked)}
          />}
          label={t('team.co-leader')}
        />
        <Tooltip
          title={t('team.co-leader-tooltip')}
          sx={{
            verticalAlign: 'text-bottom',
            fontSize: '1.2rem',
            color: 'grey.500',
            '&:hover': {
              color: 'black',
            },
          }}
        ><HelpOutline /></Tooltip>
        <br />
        <CTAButton
          id='sendInviteBtn'
          type='submit'
          sx={{ mt: { md: 2, lg: 1 }, mb: { lg: 4 } }}
          disabled={!firstname || !email || !team}
        >
          {t('team.inviteSubmit')}
        </CTAButton>
      </>}
      {errors.licences === 'no_invite' && <Typography>
        <Trans i18nKey='team.no-invite' style={{ color: 'red' }}>
          Vos licences sont épuisées ! <Link onClick={() => navigate(PRICING)}>Commandez ici</Link> de nouvelles licences.
        </Trans>
      </Typography>}
    </Box>
  )
}

export default InvitationForm
