import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { InputLabel, Select, MenuItem, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import OutlinedButton from '../_library/OutlinedButton'
import SelectAvatarForm from '../onboarding/SelectAvatarForm'
import { USER_COLLECTION } from '../../_constants/globals'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'
const PREFIX = 'MyAccountForm'

const classes = {
  root: `${PREFIX}-root`,
  avatar: `${PREFIX}-avatar`,
  textField: `${PREFIX}-textField`,
  select: `${PREFIX}-select`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    maxWidth: 400,
    marginLeft: '10%',
    marginRight: '10%',
  },

  [`& .${classes.avatar}`]: {
    margin: '0 auto',
  },

  [`& .${classes.textField}`]: {
    marginBottom: 30,
    '& input': {
      fontSize: '1.5rem',
    },
    '& label': {
      fontWeight: 'bold',
      color: 'grey',
    },
    '& label.Mui-focused': {
      color: 'grey',
    },
    '& .MuiInput-underline.Mui-focused:after': {
      borderColor: theme.palette.primary.light,
    },
    '& .MuiInput-colorSecondary.MuiInput-underline:after': {
      borderColor: theme.palette.primary.light,
    },
  },
}))

const MyAccountForm = () => {
  
  const { t } = useTranslation()
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)

  const [firstname, setFirstname] = useState(profile?.firstname)
  const [gender, setGender] = useState(profile?.gender)

  const handleSubmit = event => {
    event.preventDefault()
    userHooks.updateDoc(profile.id, { firstname, gender }, true)
  }

  if (!profile) return null
  else return (
    <Root className={classes.root} >
      <SelectAvatarForm className={classes.avatar} />
      <form onSubmit={handleSubmit}>
        <TextField
          className={classes.textField}
          name='firstname'
          label={t('firstname')}
          value={firstname || ''}
          onChange={e => setFirstname(e.target.value)}
          fullWidth
          color='secondary'
        />
        <InputLabel id='select-gender-label'>{t('gender.label')}</InputLabel>
        <Select
          labelId='select-gender-label'
          value={gender || ''}
          onChange={e => setGender(e.target.value)}
          name='gender'
          fullWidth
          variant='standard'
        >
          <MenuItem value='man'>{t('gender.man')}</MenuItem>
          <MenuItem value='woman'>{t('gender.woman')}</MenuItem>
        </Select>
        <OutlinedButton type='submit' sx={{
          my: 2,
          float: { xs: 'none', sm: 'right' },
          width: { xs: '100%', sm: 'fit-content' },
        }}>Modifier</OutlinedButton>
      </form>
    </Root>
  )
}

export default MyAccountForm
