import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardContent, Typography, CardActions, Button, Card } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import clsx from 'clsx'
import useFirestore from '../../hooks/useFirestore'
import { PROPOSAL_COLLECTION } from '../../_constants/globals'


const PREFIX = 'Question'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
  numerotation: `${PREFIX}-numerotation`,
  total: `${PREFIX}-total`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    backgroundColor: 'White',
    color: 'Black',
    maxWidth: 800,
    margin: '3rem auto',
    padding: 40,
    [theme.breakpoints.down('md')]: {
      padding: 15,
      marginTop: '1rem',
    },
  },

  [`& .${classes.title}`]: {
    fontSize: '1.6em',
    lineHeight: '1.2em',
    marginBottom: '1.2em',
    color: 'Black',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3em',
    },
    '& strong': {
      color: 'Black',
    },
  },

  [`& .${classes.button}`]: {
    display: 'block',
    color: '#2A1B38',
    width: '200px',
    fontWeight: 750,
    fontSize: '1.3em',
    lineHeight: '1em',
    textTransform: 'lowercase',
    marginTop: '0.6em',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    WebkitTransition: 'all .2s ease-in-out',
    [theme.breakpoints.down('md')]: {
      '&:active': {
        backgroundColor: '#F6E96A',
      },
    },
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: '#F6E96A',
      },
    },
    '&.selected': {
      backgroundColor: '#F6E96A',
    },
  },

  [`& .${classes.backButton}`]: {
    userSelect: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    color: '#1DCAFF',
    textTransform: 'lowercase',
    fontWeight: 750,
  },

  [`& .${classes.numerotation}`]: {
    fontSize: '35px',
    marginTop: theme.spacing(3),
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },

  [`& .${classes.total}`]: {
    color: '#5D4D74',
  },
}))

const Question = ({ goBack, question, num, next, total, answer }) => {
  
  const { t, i18n } = useTranslation()
  const proposalHooks = useFirestore(PROPOSAL_COLLECTION)
  const proposals = proposalHooks.getDocs()
  
  const language = useMemo(() => {
    if (['en', 'fr'].includes(i18n?.language.slice(0, 2)))
      return i18n?.language.slice(0, 2)
    else
      return 'en'
  }, [i18n?.language])

  return (
    <Root>
      <div className={classes.numerotation}>
        {num}<span className={classes.total}>/{ total }</span>
      </div>
      <Card className={classes.root}>
        <center>
          <CardContent>
            <Typography className={classes.title} color='textSecondary' gutterBottom>
              {question}
            </Typography>
            {proposals?.map(proposal =>
              <Button
                className={clsx(classes.button, answer && answer.proposalRef?.id === proposal.id && 'selected')}
                onClick={() => next(proposal)} variant='outlined' key={proposal.id}
              >
                {proposal.text[language]}
              </Button>,
            )}
          </CardContent>
          <CardActions>
            <Button className={classes.backButton} size='medium' onClick={() => goBack(num)}>
              <ChevronLeftIcon />
              {t('common.back')}
            </Button>
          </CardActions>
        </center>
      </Card>
    </Root>
  )
}

Question.propTypes = {
  goBack: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  num: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  answer: PropTypes.object,
}

export default Question
