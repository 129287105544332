import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Rating, Box } from '@mui/material'
import { Star as StarIcon } from '@mui/icons-material'


const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
}))

const StarsUnderCard = ({ firstname, rating, comment }) => {
  
  const labels = {
    1: `${firstname} ne se reconnait pas dans cette description : `,
    2: `${firstname} ne se reconnait pas  vraiment dans cette description : `,
    3: `${firstname} se reconnait seulement dans certains points de cette description : `,
    4: `${firstname} se reconnait en grande partie dans cette description : `,
    5: `${firstname} se reconnait complètement dans cette description : `,
  }

  return (
    <Box>
      <Box display='flex' flexDirection='column' alignItems='center'>
        {rating && <Box lineHeight='1.5rem' fontStyle='italic' fontSize='.8rem' ml={{ xs: 0, md: 2 }}>
          {labels[rating]}
        </Box>}
        <StyledRating
          name='customized-color'
          value={rating || 0}
          readOnly
          size='large'
          icon={<StarIcon fontSize='inherit' />}
        />
      </Box>
      {comment && <>
        <Box lineHeight='1.5rem' fontStyle='italic' fontSize='.8rem' ml={{ xs: 0, md: 2 }}>
          Commentaire de {firstname} :
        </Box>
        <Box color='black' ml={2}>{comment}</Box>
      </>}
    </Box>
  )
}

StarsUnderCard.propTypes = {
  firstname: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  comment: PropTypes.string,
}

export default StarsUnderCard
