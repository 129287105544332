import React from 'react'
import { Button, styled } from '@mui/material'


const FirstLevelButton = styled(Button)(({ theme }) => ({
  padding: '.5em 1.5em',
  borderRadius: '2rem',
  fontSize: '1.25rem',
  color: theme.palette.grey[100],
  fontWeight: 800,
  marginRight: theme.spacing(1),
  textTransform: 'initial',
  backgroundColor: theme.palette.secondary.main,
  '&:disabled': {
    backgroundColor: theme.palette.grey[200],
    color: 'white',
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}))

// eslint-disable-next-line react/display-name
export default props => <FirstLevelButton variant='contained' {...props} />
