import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { FilterNone } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { notificationAction } from '../../store/actions/notificationActions'


const CopyButton = ({ questions }) => {
  
  const dispatch = useDispatch()
  
  const handleCopy = () =>
    navigator.clipboard.writeText(questions.join('\n'))
      .then(() => dispatch(notificationAction('success', 'Copiées !')))
      .catch(err => dispatch(notificationAction('error', err.message)))
  
  return (
    <Button variant='text' component='span' onClick={handleCopy} sx={{ fontWeight: '700', color: 'secondary.main' }}>
      <FilterNone /> copier toutes
    </Button>
  )
}

CopyButton.propTypes = {
  questions: PropTypes.array.isRequired,
}

export default CopyButton
