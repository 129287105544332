import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, MenuItem, Select, TextField } from '@mui/material'
import Avatar from '../../_library/Avatar'
import DeleteButton from '../../_library/DeleteButton'
import { ACCESS_LEVEL_MANAGER, ACCESS_LEVEL_USER, TEAM_COLLECTION, USER_COLLECTION } from '../../../_constants/globals'
import { arrayRemove, arrayUnion } from 'firebase/firestore'
import useFirestore from '../../../hooks/useFirestore'
import useAuth from '../../../hooks/useAuth'


const UserChipEdit = ({ user }) => {
  
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const roleValue = useMemo(() => {
    if (user.accessLevel >= ACCESS_LEVEL_MANAGER && user.role === 'teamManager') return 'both'
    else if (user.role === 'teamManager') return 'teamManager'
    else if (user.accessLevel >= ACCESS_LEVEL_MANAGER) return 'manager'
    else return 'member'
  }, [user])

  const handleSelect = val => {
    if (val === 'teamManager' || val === 'both')
      teamHooks.updateDoc(profile.currentTeamSelectedRef.id, {
        managerRefs: arrayUnion(userHooks.getDocRef(user.id)),
      })
    else
      teamHooks.updateDoc(profile.currentTeamSelectedRef.id, {
        managerRefs: arrayRemove(userHooks.getDocRef(user.id)),
      })

    if (val === 'manager' || val === 'both')
      userHooks.updateDoc(user.id, { accessLevel: ACCESS_LEVEL_MANAGER })
    else
      userHooks.updateDoc(user.id, { accessLevel: ACCESS_LEVEL_USER })
  }
  
  const removeUserFromTeam = () =>
    teamHooks.updateDoc(profile.currentTeamSelectedRef.id, {
      memberRefs: arrayRemove(userHooks.getDocRef(user.id)),
      managerRefs: arrayRemove(userHooks.getDocRef(user.id)),
    })

  return (
    <Box sx={{ my: 3, mx: 2, position: 'relative', py: 1, pr: '20px', pl: '60px' }}>
      <Avatar photoUrl={user.photoUrl} sx={{ position: 'absolute', left: '-40px', top: '50%', mt: '-40px' }} />
      <Box component='form' noValidate autoComplete='off' sx={{
        display: 'inline-block',
        '& input': {
          color: 'grey.100',
        },
        '& label': {
          color: 'grey.100',
        },
      }}>
        <TextField
          name='firstname'
          value={user.firstname}
          onChange={e => userHooks.updateDoc(user.id, { firstname: e.target.value })}
          sx={{
            mr: 2,
            '& .MuiInput-underline:before': {
              borderBottomColor: 'grey.400',
            },
            '& .MuiInput-underline:hover:before': {
              borderBottomColor: 'grey.400',
            },
          }}
        />
        <Select
          id='role-select'
          value={roleValue}
          onChange={e => handleSelect(e.target.value)}
          variant='standard'
          sx={{
            mr: 2,
            minWidth: '150px',
            color: 'grey.100',
            '& .MuiSelect-icon': {
              color: 'grey.100',
            },
            '&:before': {
              borderColor: 'grey.400',
            },
            '&:hover:not(.Mui-disabled):before': {
              borderColor: 'grey.400',
            },
            '&:after': {
              borderBottomColor: 'grey.400',
            },
          }}
        >
          <MenuItem value='member'>Membre</MenuItem>
          <MenuItem value='teamManager'>Co-animateur</MenuItem>
          <MenuItem value='manager'>Manager</MenuItem>
          <MenuItem value='both'>Co-animateur et manager</MenuItem>
        </Select>
      </Box>
      <DeleteButton deleteAction={removeUserFromTeam} title='Retirer de l&apos;équipe' />
      <Box
        sx={{
          color: user.error ? 'error.main' : 'black',
          opacity: user.error ? 'unset' : .21,
          fontWeight: 600,
          mt: 1,
        }}
      >{user.email}</Box>
    </Box>
  )
}

UserChipEdit.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserChipEdit
