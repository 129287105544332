const UNIT_PRICE = 96
const VAT = .2

const fixedDecimals = num => Math.round(num * 100) / 100

const computeVATAmount = number => fixedDecimals(number * UNIT_PRICE * VAT)

const computeNetPrice = number => fixedDecimals(number * UNIT_PRICE + computeVATAmount(number))

export {
  UNIT_PRICE,
  computeVATAmount,
  computeNetPrice,
}
