import React, { useMemo } from 'react'
import LoginForm from './LoginForm'
import LoginAnimation from './LoginAnimation.jsx'
import Avatar from '../_library/Avatar'
import TmpPasswordForm from './TmpPasswordForm'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { parseParams } from '../../_helpers/helpers'
import { LOGIN } from '../../_constants/routes'
import ResetPasswordForm from './ResetPasswordForm'
import { Box, Grid, Link, Typography } from '@mui/material'


// eslint-disable-next-line react/prop-types
const Title = ({ children }) => <Typography
  variant='h1'
  fontWeight='700'
  fontSize={{ lg: '3.8rem', md: '2.7rem', sm: '1.5rem' }}
  pt={{ md: '1rem' }}
  mb={{ sm: '15px', md: '50px' }}
>{children}</Typography>

// eslint-disable-next-line react/prop-types
const Instructions = ({ children }) => <Typography variant='body1' mb='3rem' fontSize={{ md: '1.5rem', sm: '1.2rem' }} fontWeight='400'>{children}</Typography>

/**
 * This page handles auth and every auth related actions such as temporary password update and forgotten password feature
 *
 * To enable custom forgotten password feature, replace custom action URL in Firebase Authentication with "https://ORIGIN/login/reset-password"
 */
const LoginPage = () => {
  
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  
  const { email, temporaryPassword, invited, manager, photoUrl, oobCode } = useMemo(() => parseParams(location.search), [location.search])
  
  return (
    <Box sx={{ height: { md: '100%' }, width: { md: '100%' } }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} sm={7} sx={{ p: { lg: 0, xs: '1rem' }, fontSize: { xs: '1rem' } }}>
          <Box sx={{
            width: '100%',
            maxWidth: '600px',
            my: { lg: '8rem', xs: '1.5rem' },
            mx: 'auto',
          }}>
            <Routes>
              <Route exact path='/' element={<>
                <Title>{t('login.identify')}</Title>
                <Instructions>{t('login.instructions')}</Instructions>
                <LoginForm />
              </>} />
              <Route path='temporary-password' element={<>
                <Title>{t('login.temporary-password.title', { invited })}</Title>
                <Box sx={{ mb: 2, position: 'relative', marginTop: { md: '1rem' } }}>
                  <Avatar photoUrl={photoUrl} sx={{ position: { md: 'absolute' }, left: { md: '-100px' }, top: { md: '-10px' } }} />
                  <Typography variant='h3' fontWeight='700' fontSize={{ sm: '1.8rem', xs: '1rem' }}>{t('login.temporary-password.subtitle', { manager })}</Typography>
                </Box>
                <Instructions>{t('login.temporary-password.instructions')}</Instructions>
                {email && <TmpPasswordForm email={email} temporaryPassword={temporaryPassword} />}
                <Typography style={{ fontSize: '0.8rem', marginTop: 10 }}>
                  {t('login.temporary-password.confirm')}
                </Typography>
                <Link onClick={() => navigate(LOGIN) }>{t('login.temporary-password.connect')}</Link>
              </>} />
              <Route path='reset-password' element={<>
                <Title>{t('login.temporary-password.title', { invited })}</Title>
                <Instructions>{t('login.temporary-password.instructions')}</Instructions>
                {oobCode && <ResetPasswordForm oobCode={oobCode} />}
                <Typography fontSize='0.8rem' mt='1'>
                  {t('login.temporary-password.confirm')}
                </Typography>
                <Link onClick={() => navigate(LOGIN) }>{t('login.temporary-password.connect')}</Link>
              </>} />
            </Routes>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} sx={{
          bgcolor: 'grey.100',
          height: '100%',
          display: { xs: 'none', lg: 'block' },
          position: 'fixed',
          right: 0,
        }}>
          <LoginAnimation sx={{ display: 'block', margin: '8rem 4rem', width: '90%' }} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoginPage
