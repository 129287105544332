import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import fr from './_constants/i18n/fr'
import en from './_constants/i18n/en'


const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    debug: process.env.REACT_APP_DEBUG,
    saveMissing: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
  })

export default i18n
