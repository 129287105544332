import React, { useEffect, useState } from 'react'
import { Typography, TextField, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CTAButton from '../_library/FirstLevelButton'
import loadingBoussole from '../../assets/DIVERS/loadingBoussole.gif'
import { serverTimestamp } from 'firebase/firestore'
import { TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { CREATE_TEAM_ERROR, CREATE_TEAM_SUCCESS } from '../../store/types'
import { resetNotifications } from '../../store/actions/notificationActions'
import useFirestore from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


export const NewTeamForm = () => {
  
  const { t } = useTranslation()
  const dataError = useSelector(state => state.data.dataError)
  const dispatch = useDispatch()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const profile = useAuth().getProfile()
  
  const [teamName, setTeamName] = useState('')
  const [submitted, setSubmitted] = useState(false)

  function handleSubmit(event) {
    event.preventDefault ()
    setSubmitted(true)
    teamHooks.addDoc({
      name: teamName,
      managerRefs: [userHooks.getDocRef(profile.id)],
      _createdAtTime: serverTimestamp(),
      companyRef: profile.companyRef,
      memberRefs: [userHooks.getDocRef(profile.id)],
    })
      .then(() => {
        dispatch({ type: CREATE_TEAM_SUCCESS })
        setTeamName('')
        return setSubmitted(false)
      })
      .catch(err => dispatch({ type: CREATE_TEAM_ERROR, payload: err }))
      .finally(() => resetNotifications(dispatch))
  }

  useEffect(() => {
    if (submitted && dataError)
      setSubmitted(false)
  }, [submitted, dataError])

  return (
    <Box sx={{ maxWidth: '900px', m: '5vh auto 20px', p: '5%' }}>
      <Typography variant='h1' mb={{ xs: 2, lg: 6 }} fontSize={{ xs: '2.2rem', lg: '3rem' }} width={{ xs: '60%', lg: 'inherit' }}>
        {t('team.add')}
      </Typography>
      <Typography variant='body1' fontSize='1.5rem' fontWeight={300}>
        {t('team.add-paragraph')}
      </Typography>
      {submitted && <img
        src={loadingBoussole}
        alt='boussole loader'
      />}
      {(!submitted || dataError) && <Box
        component='form'
        onSubmit={handleSubmit}
        noValidate
        autoComplete='off'
        sx={{ mt: 2, maxWidth: '500px' }}
      >
        {dataError && <Typography style={{ color: 'red' }}>{dataError.message}</Typography>}
        <TextField
          name='teamName'
          type='teamName'
          label={t('team.name')}
          value={teamName}
          onChange={event => setTeamName(event.target.value)}
          color='secondary'
          required
          fullWidth
          sx={{
            mb: 4,
            mt: { xs: 1, lg: 'inherit' },
            width: { xs: '100%', lg: 'inherit' },
            '& input': {
              color: 'black',
              fontWeight: 'normal',
            },
            '& label': {
              color: 'primary.dark',
            },
            '& label.Mui-focused': {
              color: 'primary.dark',
            },
            '& .MuiInput-underline.Mui-focused:after': {
              borderColor: 'primary.light',
            },
            '& .MuiInput-colorSecondary.MuiInput-underline:after': {
              borderColor: 'primary.light',
            },
          }}
        />
        <CTAButton
          type='submit'
          disabled={!teamName}
          sx={{
            mt: { xs: 1, lg: 2 },
            mb: { xs: 4, lg: 'inherit' },
            height: { xs: '50px', lg: 'inherit' },
            width: { xs: '100%', lg: 'inherit' },
          }}
        >
          {t ('team.create')}
        </CTAButton>
      </Box>}
    </Box>
  )
}

export default NewTeamForm
