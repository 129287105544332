import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Grid, Typography } from '@mui/material'
import CTAButton from '../_library/FirstLevelButton'
import starAnimation from '../../assets/ONBOARDING/starAnimation.mp4'
import ArrowbackIosIcon from '@mui/icons-material/ArrowBackIos'
import VideoSection from './VideoSection'
import { Trans, useTranslation } from 'react-i18next'


const TalentScreen = ({ goBack, goFore }) => {
  
  const { t } = useTranslation()
  
  return (
    <Grid container>
      <Grid item xs={12} sm={10} md={7}>
        <Box sx={{ py: 6, px: 1, maxWidth: '600px', m: 'auto' }}>
          <Typography variant='h3' component='h1' fontSize='1.8rem' fontWeight={600}>{t('onboarding.noonesperfect')}</Typography>
          <Typography variant='h1' component='h2' mb={4}>{t('onboarding.perfectTeam')}</Typography>
          <Typography fontSize='1.2rem'><Trans i18nKey='onboarding.teammate-resources' /></Typography>
          <CTAButton onClick={goFore} sx={{ mt: 4 }}>{t('onboarding.tell-me-more')}</CTAButton>
          <Button onClick={goBack} sx={{ display: 'flex', textTransform: 'none', fontSize: '1.1rem', mt: 2, fontWeight: 600, color: 'primary.main' }}>
            <ArrowbackIosIcon />{t('common.back')}
          </Button>
        </Box>
      </Grid>
      <VideoSection animation={starAnimation} />
    </Grid>
  )
}

TalentScreen.propTypes = {
  goFore: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
}

export default TalentScreen
