import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CTAButton from './FirstLevelButton'
import { Box } from '@mui/material'


export const StickyScrollBtn = ({ pageScrollPercent, children, ...props }) => {
  
  const fixedWellSeen = useMemo(() => pageScrollPercent > 0.5, [pageScrollPercent])
  
  return (
    <Box sx={{
      position: fixedWellSeen ? 'fixed' : 'absolute',
      bottom: fixedWellSeen ? 0 : '-100px',
      zIndex: fixedWellSeen && 10,
      width: fixedWellSeen ? '100%' : 'initial',
      bgcolor: fixedWellSeen && 'background.default',
      ml: !fixedWellSeen && 3,
    }}>
      <CTAButton
        sx={{ m: fixedWellSeen ? 2 : 0 }}
        {...props}
      >
        {children}
      </CTAButton>
    </Box>
  )
}

StickyScrollBtn.propTypes = {
  pageScrollPercent: PropTypes.number,
  children: PropTypes.string.isRequired,
}

export default StickyScrollBtn
