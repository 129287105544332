import React, { useState } from 'react'
import { Typography, Grid, useTheme, useMediaQuery, Box } from '@mui/material'
import Balloon from '../cards/Balloon'
import { HOME, PRICING } from '../../_constants/routes'
import TeamListIcon from '../../assets/DIVERS/teamlist-icon.svg'
import NewTeamForm from './NewTeamForm'
import DisplayTeamsPanel from './DisplayTeamsPanel'
import { ACCESS_LEVEL_MANAGER } from '../../_constants/globals'
import { useNavigate } from 'react-router-dom'
import Page from '../_library/Page'
import useAuth from '../../hooks/useAuth'


export const TeamsPage = () => {
  
  const profile = useAuth().getProfile()
  const navigate = useNavigate()
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  
  const [openTeam, setOpenTeam] = useState(false)
  
  if (!profile.companyRef)
    return (<Balloon
      preset='inviteTeamFreemium'
      onClick={() => navigate(HOME)}
      onCTA={() => navigate(PRICING)}
      styleTitle={{ paddingTop: 20, fontSize: '2rem' }}
    />)
  else
    return (
      <Page withTopBar>
        <Grid container sx={{ height: '100%' }}>
          {(isUpMd || !openTeam) &&
            <Grid item xs={12} sm={7} id='leftPanel' sx={{ position: 'relative' }}>
              <Box onClick={() => setOpenTeam(true)} sx={{
                display: { xs: 'block', md: 'none' },
                borderRadius: '50%',
                top: '20px',
                position: 'absolute',
                right: '30px',
              }}>
                <Box component='img' src={TeamListIcon} alt='Team List icon' sx={{ height: '60px', width: '60px' }} />
                <Box sx={{ fontWeight: 700, textAlign: 'center', color: 'primary.main' }}>TEAM</Box>
              </Box>
              {profile.accessLevel >= ACCESS_LEVEL_MANAGER
                ? <NewTeamForm />
                : <Typography style={{ paddingTop: '3rem', paddingLeft: '3rem' }}>Vous n&apos;avez pas la permission de créer de nouvelles équipes</Typography>
              }
            </Grid>
          }
          {(isUpMd || openTeam) &&
            <Grid item xs={12} sm={5} id='rightPanel' sx={{
              bgcolor: 'primary.main',
              p: { xs: 1, md: 3 },
              height: '100%',
              width: { xs: '100%', md: 'inherit' },
              position: 'relative',
              overflowY: 'auto',
            }}>
              <Box onClick={() => setOpenTeam(false)} sx={{
                display: { xs: 'block', md: 'none' },
                borderRadius: '50%',
                top: '20px',
                position: 'absolute',
                right: '30px',
              }}>
                <Box sx={{
                  bgcolor: 'secondary.main',
                  color: 'grey.100',
                  fontWeight: 700,
                  fontSize: '2rem',
                  height: '60px',
                  width: '60px',
                  borderRadius: '50px',
                  textAlign: 'center',
                  lineHeight: 1.9,
                  margin: 'auto',
                }}>+</Box>
                <Box sx={{ fontWeight: 700, textAlign: 'center', color: 'grey.100' }}>AJOUTER</Box>
              </Box>
              <DisplayTeamsPanel />
            </Grid>
          }
        </Grid>
      </Page>
    )
}

export default TeamsPage
