import { createGenericSlice } from './createGenericSlice'
import { isIterable } from '../../_helpers/jsHelper'
import { serialize } from '../../_helpers/firestoreHelper'

const initialState = {
  answers: [],
  campfires: [],
  cards: [],
  companies: [],
  teams: [],
  users: [],
}

const slice = createGenericSlice({
  name: 'firestore',
  initialState,
  reducers: {
    success: {
      reducer: (state, { payload }) => {
        state[payload.collection] = payload.data
      },
      prepare: (collection, data) => ({
        payload: {
          collection,
          data: isIterable(data)
            ? data.map(serialize)
            : serialize(data),
        },
      }),
    },
  },
})

export const actions = slice.actions
export default slice.reducer
