import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, MenuItem, Select } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import NewUserCard from './NewUserCard'
import { USER_COLLECTION } from '../../_constants/globals'
import useFirestore from '../../hooks/useFirestore'


const UserTableToolbar = ({ selectedUsers, resetSelect }) => {
  
  const userHooks = useFirestore(USER_COLLECTION)

  const [selectedAction, setSelectedAction] = useState(null)
  const [displayNewUserForm, setDisplayNewUserForm] = useState(false)

  const handleAction = () => {
    switch (selectedAction) {
      case 'deleteUser':
        return Promise.all(selectedUsers.map(user => userHooks.updateDoc(user.id, { companyRef: null }))).then(resetSelect)
      default:
        return null
    }
  }

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      {displayNewUserForm && <NewUserCard onClose={() => setDisplayNewUserForm(false)} />}
      <Select
        variant='outlined'
        value={selectedAction || ''}
        onChange={e => setSelectedAction(e.target.value)}
        sx={{ minWidth: '150px', height: '36px', mr: 2 }}
      >
        <MenuItem value='deleteUser'>Supprimer</MenuItem>
      </Select>
      <Button
        variant='contained'
        onClick={handleAction}
        disabled={selectedUsers?.length === 0 || !selectedAction}
        sx={{
          borderRadius: '40px',
          boxShadow: 'none',
          color: 'white',
          textTransform: 'initial',
          backgroundColor: 'grey.200',
        }}
      >Appliquer</Button>
      {!displayNewUserForm && <Box sx={{ float: 'right' }}>
        <Button id='newUserBtn' onClick={() => setDisplayNewUserForm(true)} sx={{ color: 'tertiary.main', textTransform: 'initial' }}>
          <AddCircle />&nbsp;Nouveau membre
        </Button>
      </Box>}
    </Box>
  )
}

UserTableToolbar.propTypes = {
  selectedUsers: PropTypes.array.isRequired,
  resetSelect: PropTypes.func.isRequired,
}

export default UserTableToolbar
