import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'


const PREFIX = 'Sources'

const classes = {
  sourcesContainer: `${PREFIX}-sourcesContainer`,
  source: `${PREFIX}-source`,
  line: `${PREFIX}-line`,
}

const Root = styled('div')(() => ({
  [`&.${classes.sourcesContainer}`]: {
    marginTop: '2rem',
    marginBottom: '2rem',
    display: 'block',
  },

  [`& .${classes.source}`]: {
    fontStyle: 'italic',
    fontSize: '0.8rem',
    fontWeight: '400',
  },

  [`& .${classes.line}`]: {
    width: '50%',
    marginRight: '50%',
  },
}))

const Sources = ({ sources }) => (
  <Root className={classes.sourcesContainer}>
    <hr className={classes.line} />
    {sources?.map((item, index) =>
      <Typography className={classes.source} key={index}>
        <sup>{index + 1}</sup>
        {item}
      </Typography>,
    )}
  </Root>
)

Sources.propTypes ={
  sources: PropTypes.array.isRequired,
}

export default Sources
