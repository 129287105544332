import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { toPng } from 'html-to-image'
import PersonalityGraph from '../_library/PersonalityGraph'
import { Box } from '@mui/material'


export const GraphsToImage = ({ teammate, setMyGraph, setTeammateGraph, setTeamGraph, profile, teamMembers }) => {
  const teamMembersForGraph = useMemo(() => teamMembers.filter(u => u.profileType).map(el => ({ ...el, selected: true })), [teamMembers])

  const myGraphRef = useRef()
  const teammateGraphRef = useRef()
  const teamGraphRef = useRef()

  const convertGraphToImage = element =>
    new Promise(resolve => {
      element.style.display = 'block'
      setTimeout(
        () => toPng(element)
          .then(dataUrl => dataUrl && dataUrl !== 'data:,' && resolve(dataUrl))
          .finally(() => { element.style.display = 'none' })
        , 300,
      )
    })

  useEffect(() => {
    myGraphRef.current && convertGraphToImage(myGraphRef.current).then(setMyGraph)
  }, [profile, setMyGraph])

  useEffect(() => {
    teammateGraphRef.current && convertGraphToImage(teammateGraphRef.current).then(setTeammateGraph)
  }, [teammate, setTeammateGraph])

  useEffect(() => {
    teamGraphRef.current && convertGraphToImage(teamGraphRef.current).then(setTeamGraph)
  }, [teamMembersForGraph, setTeamGraph])

  return (
    <Box sx={{ width: 'fit-content' }}>
      <PersonalityGraph users={[profile.testResults ? profile : teammate]} displayHeart displayWeb ref={myGraphRef} sx={{ display: 'none' }} />
      <PersonalityGraph users={[teammate]} displayHeart displayWeb ref={teammateGraphRef} sx={{ display: 'none' }} />
      <PersonalityGraph users={teamMembersForGraph} displayAvatars displayHeart ref={teamGraphRef} sx={{ display: 'none' }} />
    </Box>
  )
}

GraphsToImage.propTypes = {
  teammate: PropTypes.object.isRequired,
  setMyGraph: PropTypes.func.isRequired,
  setTeammateGraph: PropTypes.func.isRequired,
  setTeamGraph: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  teamMembers: PropTypes.array.isRequired,
}

export default GraphsToImage
