import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, FormControl, FormControlLabel, Link, TextField } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import CTAButton from '../_library/FirstLevelButton'
import { RGPD, AVATAR, HOME } from '../../_constants/routes'
import { REGEX_MAIL, INVALID_PRO_MAIL_EXTENSIONS, REGEX_PWD } from '../../_constants/regex'
import { useNavigate } from 'react-router-dom'
import LoadingBoussole from '../_library/LoadingBoussole'
import useAuth from '../../hooks/useAuth'


const FreemiumTestEndForm = () => {
  
  const { t } = useTranslation()
  const profile = useAuth().getProfile()
  const navigate = useNavigate()
  const auth = useAuth()
  
  const [firstname, setFirstname] = useState(null)
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [acceptedConditions, setAcceptedConditions] = useState(false)
  const [newsletterAcceptance, setNewsletterAcceptance] = useState(false)
  const [errors, setErrors] = useState({})
  const [isSubmitted, setSubmitted] = useState(false)
  
  useEffect(() => {
    if (email && email.length > 0 && !email.match(REGEX_MAIL))
      setErrors(prev => ({ ...prev, email: t('login.error.invalid-email') }))
    if (new RegExp(INVALID_PRO_MAIL_EXTENSIONS.join('|')).test(email) && !email.endsWith('_'))
      setErrors(prev => ({ ...prev, email: t('error.email/not-professional') }))
    if (password && !password.match(REGEX_PWD) )
      setErrors(prev => ({ ...prev, password: t('login.error.password-length') }))
    return () => setErrors({})
  }, [email, password, t])
  
  const enableSubmit = useMemo(
    () => email && firstname && password && acceptedConditions && Object.keys(errors).length === 0 && !isSubmitted,
    [email, firstname, password, acceptedConditions, errors, isSubmitted],
  )
  
  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    // The character _ at the end of the email let users overpass professional emails limitation
    const realEmail = email.endsWith('_') ? email.slice(0, -1) : email
    auth.convertAnonymousUserAction(realEmail.toLowerCase(), password, firstname, newsletterAcceptance)
      .then(() => navigate(AVATAR))
      .catch(err => {
        if (err.code === 'auth/email-already-in-use' || err.code === 'auth/invalid-email')
          setErrors(prev => ({ ...prev, email: t('error.' + err.code) }))
        else if (err.code === 'auth/provider-already-linked')
          return navigate(HOME)
        else
          console.error(err)
        return setSubmitted(false)
      })
  }
  
  if (!profile?.profileType) return <LoadingBoussole fullscreen />
  return (
    <form onSubmit={handleSubmit} noValidate>
      <TextField
        name='firstname'
        type='firstname'
        label={t('common.firstname')}
        value={firstname || ''}
        onChange={e => setFirstname(e.target.value)}
        fullWidth
        color='primary'
        disabled={isSubmitted}
        sx={{ my: 1 }}
      />
      <TextField
        FormHelperTextProps={{ sx: { color: 'secondary.main' } }}
        name='email'
        type='email'
        label={t('common.professional_email')}
        value={email || ''}
        onChange={e => setEmail(e.target.value.toLowerCase())}
        fullWidth
        color='primary'
        disabled={isSubmitted}
        error={!!errors.email}
        helperText={errors.email}
        sx={{ my: 1 }}
      />
      <TextField
        name='password'
        type='password'
        label={t('Choisir un mot de passe (8 caractères minimum)')}
        value={password || ''}
        onChange={e => setPassword(e.target.value)}
        fullWidth
        color='primary'
        disabled={isSubmitted}
        error={!!errors.password}
        helperText={errors.password}
        sx={{ my: 1 }}
      />
      <FormControl required>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptedConditions}
              onChange={e => setAcceptedConditions(e.target.checked)}
              name='conditionCheck'
              color='primary'
            />
          }
          label={
            <Trans i18nKey='test.end-cgv'>
              J&apos;accepte les conditions générales d&apos;utilisation. Consultez notre&nbsp;
              <Link onClick={() => navigate(RGPD)} sx={{ fontWeight: 700 }}>politique RGPD.</Link>
            </Trans>
          }
          sx={{ my: 1 }}
        />
      </FormControl>
      <FormControl required>
        <FormControlLabel
          control={
            <Checkbox
              checked={newsletterAcceptance}
              onChange={e => setNewsletterAcceptance(e.target.checked)}
              name='conditionCheck'
              color='primary'
            />
          }
          label={t('test.end-advises')}
          sx={{ my: 1 }}
        />
      </FormControl>
      <CTAButton id='signupBtn' type='submit' disabled={!enableSubmit} sx={{ mt: 2, mx: 'auto' }}>
        {t ('login.signup')}
      </CTAButton>
    </form>
  )
}

export default FreemiumTestEndForm
