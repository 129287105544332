import { useEffect, useMemo, useState } from 'react'
import { ACCESS_LEVEL_MANAGER, CARD_COLLECTION, TEAM_COLLECTION } from '../_constants/globals'
import useFirestore from './useFirestore'
import useAuth from './useAuth'
import useCards from './useCards'


/**
 * usePath returns path object property into which all displays related to paths are combined.
 *
 * @returns {object} path
 */
const usePath = () => {
  
  /**
   * Display team section cards when :
   * - user has read personal cards
   * AND a team is selected
   *  OR user is not in a company
   *  OR user is Manager
   */
  const [displayTeamSectionCards, setDisplayTeamSectionCards] = useState(null)
  const [lockTeamSection, setLockTeamSection] = useState(null)
  const [displayActivatedTeamBalloon, setDisplayActivatedTeamBalloon] = useState(null)
  const [displayInviteTeamBalloon, setDisplayInviteTeamBalloon] = useState(null)
  const [lockTeamDebriefCards, setLockTeamDebriefCards] = useState(null)
  
  const profile = useAuth().getProfile()
  const cardHooks = useFirestore(CARD_COLLECTION)
  const cards = cardHooks.getDocs()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHooks.getDocs()
  const { aboutYouCards, aboutTeamCards } = useCards()
  
  const team = useMemo(() => dbTeams?.find(t => t.id === profile.currentTeamSelectedRef?.id), [dbTeams, profile?.currentTeamSelectedRef])
  
  useEffect(() => {
    const aboutYouCardsFinished = aboutYouCards && aboutYouCards.length > 0 && aboutYouCards.every(card => card.read)
    setDisplayTeamSectionCards(aboutYouCardsFinished)
  }, [aboutYouCards])
  
  useEffect(() => {
    setLockTeamSection(!profile.companyRef && !profile.teamRefs)
  }, [profile])
  
  useEffect(() => {
    setDisplayActivatedTeamBalloon(
      team
      && cards?.some(card => card.rating && card.userRef.id === profile.id)
      && !team.teamCardReadByUserRefs?.some(element => element.id === profile.id),
    )
  }, [profile, team, cards])
  
  useEffect(() => {
    const talentExtCard = cards?.find(card => card.section === 'about-team' && card.userRef.id === profile?.id)
    setDisplayInviteTeamBalloon(
      profile.companyRef && profile.accessLevel >= ACCESS_LEVEL_MANAGER && !team && talentExtCard?.rating,
    )
  }, [profile, team, cards])
  
  useEffect(() => {
    const aboutTeamCardsFinished = aboutTeamCards?.every(card => card.readByUserRefs?.some(ref => ref.id === profile.id))
    setLockTeamDebriefCards(!profile.companyRef || !aboutTeamCardsFinished )
  }, [profile, team, aboutTeamCards])
  
  return { displayTeamSectionCards, lockTeamSection, displayActivatedTeamBalloon, displayInviteTeamBalloon, lockTeamDebriefCards }
}

export default usePath
