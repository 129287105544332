import React from 'react'
import PropTypes from 'prop-types'
import { Backdrop, Box } from '@mui/material'
import loadingBoussole from '../../assets/DIVERS/loadingBoussole.gif'


export const LoadingBoussole = ({ fullscreen }) => fullscreen
  ? <Backdrop sx={{ backgroundColor: 'background.default', zIndex: 10 }} open><img src={loadingBoussole} alt='boussole loader' /></Backdrop>
  : <Box component='img' src={loadingBoussole} alt='boussole loader' sx={{ display: 'block', m: 'auto' }} />

LoadingBoussole.propTypes = {
  fullscreen: PropTypes.bool,
}

export default LoadingBoussole
