import React from 'react'
import { Box, Paper } from '@mui/material'
import PropTypes from 'prop-types'


const SquaredBalloon = ({ avatar, children, sx }) => (
  <Paper elevation={0} sx={{ bgcolor: 'grey.100', width: '100%', minHeight: '100px', position: 'relative', p: 1, ...sx }}>
    {children}
    <Box sx={{ position: 'absolute', width: 20, height: 20, rotate: '45deg', bottom: 27, right: -10, bgcolor: 'inherit' }} />
    <Box sx={{ position: 'absolute', bottom: 0, right: -100 }}>{avatar}</Box>
  </Paper>
)

SquaredBalloon.propTypes = {
  avatar: PropTypes.object,
  children: PropTypes.elementType,
  sx: PropTypes.object,
}

export default SquaredBalloon
