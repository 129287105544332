import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'


const PREFIX = 'SumUp'

const classes = {
  sumUpContainer: `${PREFIX}-sumUpContainer`,
  firstLine: `${PREFIX}-firstLine`,
  list: `${PREFIX}-list`,
  points: `${PREFIX}-points`,
}

const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`&.${classes.sumUpContainer}`]: {
    border: '2px solid',
    borderColor: theme.palette.tertiary?.main,
    color: theme.palette.tertiary?.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.firstLine}`]: {
    fontWeight: 600,
    paddingLeft: theme.spacing(1),
  },

  [`& .${classes.list}`]: {
    display: 'table',
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.points}`]: {
    verticalAlign: 'middle',
    display: 'list-item',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    lineHeight: '1.5rem',
  },
}))

export const SumUp = ({ list }) => (
  <Root className={classes.sumUpContainer}>
    <p className={classes.firstLine}>En deux mots:</p>
    <ul className={classes.list}>
      {list?.map((item, key) =>
        <li className={classes.points} key={key}>{parse(item)}</li>,
      )}
    </ul>
  </Root>
)

SumUp.propTypes = {
  list: PropTypes.array.isRequired,
}

export default SumUp
