import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { TEAM } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import { TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { Box, Typography } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


export const TeamChip = ({ team }) => {

  const navigate = useNavigate()
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  const teamHooks = useFirestore(TEAM_COLLECTION)
  
  const members = useMemo(() => {
    if (!team.memberRefs || team.memberRefs.length === 0)
      return 'Pas de membre'
    else if (team.memberRefs.length === 1)
      return '1 membre'
    else
      return team.memberRefs.length + ' membres'
  }, [team.memberRefs])
  
  const handleClick = () => {
    userHooks.updateDoc(profile.id, { currentTeamSelectedRef: teamHooks.getDocRef(team.id) })
      .then(() => setTimeout(() => navigate(TEAM), 10))
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        mt: 2,
        p: 1,
        pl: '40px',
        border: '1px solid',
        borderColor: 'grey.100',
        borderRadius: '10px',
        cursor: 'pointer',
        color: 'grey.100',
        backgroundColor: team.error && 'secondary.main',
      }}
    >
      <Typography component='span' mr={3} fontWeight={600}>{team.name}</Typography>
      <Typography component='span' sx={{ float: 'right', color: 'white' }}>{members}</Typography>
    </Box>
  )
}

TeamChip.propTypes = {
  team: PropTypes.object.isRequired,
}

export default TeamChip
