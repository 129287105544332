import React  from 'react'
import { Box, Grid, Typography } from '@mui/material'
import PriceCalculatorForm from './PriceCalculatorForm'
import TrustBox from './TrustBox'
import tickImg from '../../assets/DIVERS/tick.svg'
import { useTranslation } from 'react-i18next'
import Page from '../_library/Page'


export const PricingPage = () => {
  
  const { t } = useTranslation()
  
  return (
    <Page withTopBar>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} sm={7} sx={{ p: 2 }}>
          <Box sx={{ maxWidth: '550px', mt: { xs: 2, sm: '20%' }, mx: 'auto' }}>
            <Typography variant='h1' fontSize={{ sm: '1.85rem', md: '2.85rem' }}>
              {t('pricing.how-many-people')}
            </Typography>
            <PriceCalculatorForm />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} sx={{ p: { xs: 2, md: 6 }, bgcolor: 'primary.main', color: 'white' }}>
          <TrustBox />
          <Typography variant='h4' fontWeight={700} color='white' sx={{ opacity: .85 }}>Vous aurez accès à :</Typography>
          <Box component='ul' sx={{
            listStyleImage: `url(${tickImg})`,
            listStylePosition: 'inside',
            paddingInlineStart: 0,
            '& li': {
              mb: 3,
            },
          }}>
            <li>Votre positionnement sur la cartographie Boussole</li>
            <li>Aux talents de vos collaborateurs</li>
            <li>Aux questions de 1:1 pour chacun de vos collaborateurs selon leur personnalité</li>
            <li>La cartographie des forces de votre équipe</li>
            <li>Le blason de votre équipe</li>
            <li>L&apos;analyse de la culture de votre équipe et nos recommandations stratégiques</li>
            <li>Aux guides de conversation personnalisés pour optimiser votre fonctionnement d&apos;équipe</li>
            <li>L&apos;option d&apos;inviter des collaborateurs avec des adresses gmail, hotmail, etc.</li>
          </Box>
        </Grid>
      </Grid>
    </Page>
  )
}

export default PricingPage
