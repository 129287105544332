import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import LoginAnimation from '../auth/LoginAnimation.jsx'
import FreemiumTestEndForm from './FreemiumTestEndForm'
import { useTranslation } from 'react-i18next'


const FreemiumTestEnd = () => {
  const { t } = useTranslation()
  
  return (
    <Grid container height='100%'>
      <Grid item xs={12} sm={7} sx={{ p: 2 }}>
        <Box maxWidth='600px' mx='auto'>
          <Typography variant='h2'>
            {t('test.end-thanks')}
          </Typography>
          <Typography variant='h1' mb={{ xs: 2, lg: 4 }} fontSize={{ xs: '2.7rem', lg: '3.8rem' }}>
            {t('test.end-subscription')}
          </Typography>
          <Typography variant='body1'>
            {t('test.end-paragraph')}
          </Typography>
          <FreemiumTestEndForm />
        </Box>
      </Grid>
      <Grid item xs={12} lg={5} sx={{
        bgcolor: 'grey.100',
        height: '100%',
        display: { xs: 'none', lg: 'initial' },
      }}>
        <LoginAnimation sx={{
          display: 'block',
          margin: '8rem 4rem',
          width: '90%',
        }} />
      </Grid>
    </Grid>
  )
}

export default FreemiumTestEnd
