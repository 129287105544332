import React, { useEffect, useState } from 'react'
import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CompanyUpdateForm from './CompanyUpdateForm'
import { computeNetPrice, computeVATAmount, UNIT_PRICE } from '../../_helpers/pricingHelper'
import { useMatch, useNavigate } from 'react-router-dom'
import { PAYMENT_SUMMARY, PRICING } from '../../_constants/routes'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import Page from '../_library/Page'


const PaymentSummaryPage = () => {
  
  const { t } = useTranslation()
  const match = useMatch(PAYMENT_SUMMARY)
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  
  const number = match.params.number
  
  const [openOrder, setOpenOrder] = useState(isMdUp)
  
  useEffect(() => window.scrollTo(0, 0), [])
  
  return (
    <Page withTopBar>
      <Grid container minHeight='100%'>
        <Grid item xs={12} sm={7} sx={{ p: 2 }}>
          <Box sx={{ maxWidth: '550px', mt: { xs: 2, sm: '20%' }, mx: 'auto' }}>
            <Typography variant='h2'>{t('billing.finalize-your-order')}</Typography>
            <Typography variant='h1' my={3}>{t('billing.title')}</Typography>
            {number > 0 && <CompanyUpdateForm />}
          </Box>
        </Grid>
        <Grid
          item xs={12} sm={5}
          onClick={() => setOpenOrder(prev => !prev || isMdUp)}
          sx={{
            color: 'white',
            backgroundColor: 'primary.main',
            width: '100%',
            pt: openOrder ? 10 : 0,
            px: 4,
          }}
        >
          {!isMdUp && <Typography variant='body1' color='grey.100' fontSize='1.4rem'>
            {openOrder
              ? <><ArrowDropDown /> Masquer l&apos;ordre</>
              : <><ArrowDropUp /> Voir l&apos;ordre</>
            }
          </Typography>}
          <Typography variant='h2'>{t('billing.your-order')}</Typography>
          <Box component='table' sx={{
            border: '0px solid transparent',
            mt: { xs: 2, md: 8 },
            borderSpacing: 10,
            '& th, td': {
              width: { md: '20%' },
              paddingLeft: { md: '10%' },
            },
            '& td': {
              borderTop: 'solid 0px transparent',
              fontSize: '1.2rem',
            },
            '& td:last-child': {
              fontWeight: 700,
            },
          }}>
            <tbody>
              <tr>
                <td><b>{number}</b> licence{number > 1 ? 's' : ''} à</td>
                <td>{UNIT_PRICE.toFixed(2).replace('.', ',')}€</td>
              </tr>
              <tr>
                <td>Total hors taxes</td>
                <td>{(number * UNIT_PRICE).toFixed(2).replace('.', ',')}€</td>
              </tr>
              <tr>
                <td>TVA (20%)</td>
                <td>{computeVATAmount(number).toFixed(2).replace('.', ',')}€</td>
              </tr>
              <Box component='tr' sx={{ color: 'grey.100' }}>
                <td>
                  <Typography variant='body1' fontSize='30px'>Total TTC</Typography>
                </td>
                <td>
                  <Typography variant='body1' fontSize='30px'>
                    {computeNetPrice(number).toFixed(2).replace('.', ',')}€
                  </Typography>
                </td>
              </Box>
            </tbody>
          </Box>
          <Link onClick={() => navigate(PRICING)} color='white' fontSize={20} fontWeight={700} mt={4} display='block' sx={{
            opacity: .5,
            '&:hover': {
              opacity: 1,
            },
          }}>&lt; Modifier la commande</Link>
        </Grid>
      </Grid>
    </Page>
  )
}

export default PaymentSummaryPage
