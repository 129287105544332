/**
 * Cross Project constants
 *
 * This file purpose is to centralise all constants used across Boussole subprojects
 * It is part of the project root folder and scripts may copy it in some subfolders if needed
 */
module.exports = {
  REGION_LOCATION: 'europe-west1',
  
  ANSWER_COLLECTION: 'answers',
  CARD_TEMPLATE_COLLECTION: 'cardTemplates',
  CAMPFIRE_COLLECTION: 'campfires',
  CAMPFIRE_TEMPLATE_COLLECTION: 'campfireTemplates',
  CARD_COLLECTION: 'cards',
  COMPANY_COLLECTION: 'companies',
  CRASH_REPORT_COLLECTION: 'crashReports',
  EMAIL_COLLECTION: 'emails',
  ONE_ONE_QUESTION_COLLECTION: 'oneOneQuestions',
  ONE_ONE_SESSION_COLLECTION: 'oneOneSessions',
  ONE_ONE_TOPIC_COLLECTION: 'oneOneTopics',
  PAYMENT_COLLECTION: 'payments',
  PROPOSAL_COLLECTION: 'proposals',
  QUESTION_COLLECTION: 'questions',
  SEQUENCE_COLLECTION: 'sequences',
  TALENT_COLLECTION: 'talents',
  TEAM_COLLECTION: 'teams',
  USER_COLLECTION: 'users',
  
  ACCESS_LEVEL_USER: 25,
  ACCESS_LEVEL_MANAGER: 50,
  ACCESS_LEVEL_ADMIN: 75,
  ACCESS_LEVEL_SUPER_ADMIN: 100,
  
  ROLE_CONSULTANT: 'consultant',
  
  EMAIL_INVITE: 'invite',
  EMAIL_NEW_TEAM_INVITE: 'newTeamInvite',
  EMAIL_RE_INVITE: 'reInvite',
  EMAIL_NOTIF_TEAMMATES_ON_RATE_EXT_CARD: 'notifTeammatesWhenTeammemberRatedExtCard',
  EMAIL_NOTIF_UNLOGGED_TEAMMATES_ON_RATE_EXT_CARD: 'notifUnloggedTeammatesWhenTeammemberRatedExtCard',
  EMAIL_INVITE_MANAGER: 'inviteManager',
  EMAIL_INVITE_MEMBER: 'inviteMember',
  EMAIL_SAFE_COCOON: 'safeCocoon',
  EMAIL_TEAM_BUILDING: 'teamBuilding',
  EMAIL_TEST_REMINDER: 'testRemind',
  EMAIL_RESET_PASSWORD: 'resetPassword',
  EMAIL_BAD_RATING: 'badRating',
  
  CONVERSATION_TYPES: {
    SAFE_COCOON: 'safeCocoon',
    TEAM_BUILDING: 'teamBuilding',
    CAMPBASE_1: 'campBase1',
    CAMPBASE_2: 'campBase2',
    CAMPBASE_3: 'campBase3',
    CAMPBASE_4: 'campBase4',
  },
  
  PROFILE_STORAGE_REF: 'profile-pictures',
  LOGO_STORAGE_REF: 'logo-pictures',
}
