import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Select, InputLabel, MenuItem, FormControl, Avatar, Typography, Box, Link } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { CARD } from '../../_constants/routes'
import CardHeader from '../cards/CardHeader'
import { ONE_TO_ONE_BUILDER } from '../../_constants/cardTypes'
import Page from '../_library/Page'
import Topics from './Topics'
import { ONE_ONE_QUESTION_COLLECTION, ONE_ONE_SESSION_COLLECTION, ONE_ONE_TOPIC_COLLECTION, TALENT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import NewQuestionForm from './NewQuestionForm'
import CustomQuestion from './CustomQuestion'
import CTAButton from '../_library/FirstLevelButton'
import CopyButton from './CopyButton'
import Questions from './Questions'
import { compact, uniqBy } from 'lodash'
import OneToOnePdf from './OneToOnePdf'
import { pdf } from '@react-pdf/renderer'
import GraphsToImage from './GraphsToImage'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


const OneToOnePage = () => {
  const profile = useAuth().getProfile()
  const consultantMode = useSelector(state => state.data.consultantMode)
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const questionHooks = useFirestore(ONE_ONE_QUESTION_COLLECTION)
  const questions = questionHooks.getDocs()
  const topicHooks = useFirestore(ONE_ONE_TOPIC_COLLECTION)
  const topics = topicHooks.getDocs()
  const talentHooks = useFirestore(TALENT_COLLECTION)
  const sessionHooks = useFirestore(ONE_ONE_SESSION_COLLECTION)
  const dbSessions = sessionHooks.getDocs()
  const dispatch = useDispatch()
  
  const [teammate, setTeammate] = useState(null)
  const [selectedTopics, setSelectedTopics] = useState([])
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [myGraph, setMyGraph] = useState(null)
  const [teammateGraph, setTeammateGraph] = useState(null)
  const [teamGraph, setTeamGraph] = useState(null)
  const talent = useSelector(state => state.firestore.talents?.find(t => t.id === teammate?.profileType))
  
  const teamMembers = useMemo(() => dbUsers?.filter(u => u.teamRefs?.some(t => t.id === profile.currentTeamSelectedRef?.id && u.profileType)), [dbUsers, profile])
  
  useEffect(() => {
    topicHooks.listen({ orderBy: [['order', 'asc']] })
    questionHooks.listen({ orderBy: [['order', 'asc']] })
    talentHooks.listen()
    return () => {
      topicHooks.unsubscribe()
      questionHooks.unsubscribe()
      talentHooks.unsubscribe()
    }
  }, [])
  
  useEffect(() => {
    if (!!profile && !!teammate) {
      sessionHooks.listen({
        where: [
          ['managerRef', '==', userHooks.getDocRef(profile?.id)],
          ['userRef', '==', userHooks.getDocRef(teammate?.id)],
        ],
      })
      return sessionHooks.unsubscribe
    }
  }, [profile?.id, teammate?.id])
  
  const session = useMemo(
    () => dbSessions?.find(s => s.managerRef.id === profile.id && s.userRef.id === teammate?.id),
    [dbSessions, profile?.id, teammate?.id],
  )
 
  const profileFilteredQuestions = useMemo(() => questions?.filter(q =>
    q.onlyApplyForProfiles?.includes(teammate?.profileType) || q.managerRef?.id === profile.id,
  ), [questions, profile?.id, teammate?.profileType])
  const oneOneCustomQuestions = useMemo(() => profileFilteredQuestions?.filter(q => q.oneOneTopic?.slug === 'custom-questions'), [profileFilteredQuestions])
  const talentExtUrl = useMemo(
    () => teammate?.id && `${window.location.origin}${CARD}/talent-ext/${teammate.id}?consultantMode=${consultantMode ? 'true' : 'false'}`,
    [teammate?.id, consultantMode],
  )
  
  // Unselect questions on uncheck topic
  useEffect(() => {
    setSelectedQuestions(prev => prev.filter(q => selectedTopics.some(t => t.id === q.oneOneTopicRef.id)))
  }, [selectedTopics])
  
  // Load history
  const loadedQuestions = useMemo(() => !!questions && !!topics, [questions, topics])
  useEffect(() => {
    if (loadedQuestions) {
      const filteredQuestions = session
        ? compact(session.questionRefs.map(ref => questions.find(q => q.id === ref.id)))
        : []
      setSelectedTopics(uniqBy(filteredQuestions.map(q => topics.find(t => t.id === q.oneOneTopicRef.id)), 'id'))
      setSelectedQuestions(filteredQuestions)
    }
  }, [session, loadedQuestions, teammate?.id])
  
  const generatePdf = useCallback(() => {
    const sortedQuestions = selectedQuestions.sort((q1, q2) => {
      const cat1 = topics.find(c => c.id === q1.oneOneTopicRef.id)
      const cat2 = topics.find(c => c.id === q2.oneOneTopicRef.id)
      return 100 * (cat1.order - cat2.order) + q1.order - q2.order
    })
    console.log('sortedQuestions', sortedQuestions)
    sessionHooks.setDoc(session?.id, {
      managerRef: userHooks.getDocRef(profile.id),
      userRef: userHooks.getDocRef(teammate.id),
      questionRefs: sortedQuestions.map(q => questionHooks.getDocRef(q.id)),
    })
    pdf(<OneToOnePdf
      teamGraph={teamGraph}
      teammateGraph={teammateGraph}
      myGraph={myGraph}
      user={profile}
      teammate={teammate}
      selectedQuestions={sortedQuestions}
      oneOneCategories={topics}
      talent={talent}
    />).toBlob().then(blob => window.open(window.URL.createObjectURL(blob)))
  }, [myGraph, profile, selectedQuestions, session?.id, talent, teamGraph, teammate, teammateGraph, topics, dispatch])
  
  return (
    <Page withTopBar>
      <CardHeader title='Préparez vos duos' subtitle='Les bonnes questions, aux bonnes personnes.' type={ONE_TO_ONE_BUILDER} />
      <Box sx={{
        my: { xs: 0, md: 3 },
        mx: { xs: 1, md: 'auto' },
        pl: { xs: 0, md: '11%' },
        maxWidth: { xs: '100%', md: '85%' },
        width: '600px',
      }}>
        <Typography sx={{ fontWeight: 'bold' }}>
          Cette carte vous partage des questions 100% personnalisées selon la personnalité de vos équipiers.
        </Typography>
        {teamMembers && <FormControl sx={{ m: theme => theme.spacing(8, 0, 1), width: '100%', flexDirection: 'row' }}>
          <InputLabel id='select-member-label' sx={{
            color: 'primary.dark',
            transform: 'translate(0, 16px) scale(1)',
            '&.MuiInputLabel-animated': {
              transform: 'translate(0, -9px) scale(1)',
            },
          }}>Sélectionnez un coéquipier</InputLabel>
          <Select
            id='select-member'
            labelId='select-member-label'
            onChange={e => setTeammate(teamMembers.find(u => u.id === e.target.value))}
            value={teammate?.id || ''}
            variant='standard'
            sx={{ minWidth: '200px', width: 'fit-content' }}
          >
            {teamMembers.map(member =>
              <MenuItem value={member.id} key={member.id}>
                <Avatar alt={member.firstname} src={member.photoUrl} sx={{ mr: 1, display: 'inline-flex', verticalAlign: 'middle' }} />
                {member.firstname}
              </MenuItem>,
            )}
          </Select>
          {teammate && <Link
            sx={{ color: 'tertiary.light', mt: '28px', ml: 1 }}
            onClick={() => window.open(talentExtUrl)}
          >Voir son profil boussole &gt;</Link>}
        </FormControl>}
        {teammate && <>
          <Topics teammate={teammate} selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics} />
          {selectedTopics.length > 0 && <Box sx={{
            mt: 3,
            mx: { xs: 0, md: - 4 },
            p: { md: 4, sm: 0 },
            border: { md: 'solid 1px' },
            borderColor: { md: 'grey.200' },
          }}>
            <Typography fontWeight='700'>Cochez les questions que vous voudriez poser :</Typography>
            {selectedTopics.filter(t => t.slug !== 'custom-questions').map(topic =>
              <Questions
                key={topic.id}
                topic={topic}
                questions={profileFilteredQuestions?.filter(q => q.oneOneTopicRef.id === topic.id)}
                selectedQuestions={selectedQuestions}
                teammate={teammate}
                selectHandler={(val, question) => setSelectedQuestions(prev => val
                  ? profileFilteredQuestions.filter(q => prev.some(p => p.id === q.id) || question.id === q.id)
                  : prev.filter(q => q.id !== question.id),
                )}
              />,
            )}
          </Box>}
          <Typography variant='h2' mt={3} mb={0} fontSize='1.35rem' color='primary'>Mes questions</Typography>
          <NewQuestionForm onComplete={question => setSelectedQuestions(prev => [...prev, question])} order={oneOneCustomQuestions?.length} />
          {oneOneCustomQuestions?.map(question =>
            <CustomQuestion
              key={question.id}
              text={question.title.fr.man}
              selected={selectedQuestions.some(q => question.id === q.id)}
              selectHandler={val => setSelectedQuestions(prev => val ? [...prev, question] : prev.filter(q => q.id !== question.id))}
              deleteHandler={() => questionHooks.deleteDoc(question.id).then(() => setSelectedQuestions(prev => prev.filter(q => q.id !== question.id)))}
            />,
          )}
          <CTAButton onClick={generatePdf} disabled={selectedQuestions?.length === 0} sx={{ mt: 3 }}>Générer le pdf</CTAButton>
          <div>
            <Typography component='span' mt={2} display='inline-block'>ou</Typography>
            <CopyButton questions={selectedQuestions.map(q => q.title.fr[teammate.gender])} />
            <Typography component='span' mt={2} display='inline-block'>les questions sélectionnées</Typography>
          </div>
          <GraphsToImage
            teammate={teammate}
            setMyGraph={setMyGraph}
            setTeammateGraph={setTeammateGraph}
            setTeamGraph={setTeamGraph}
            profile={profile}
            teamMembers={teamMembers}
          />
        </>}
      </Box>
    </Page>
  )
}

export default OneToOnePage
