import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import Gandhi from '../../assets/CARDS/QUOTES/Ghandi.png'
import HenriFord from '../../assets/CARDS/QUOTES/HenriFord.png'


const preSets = {
  nonViolenceGandhi: {
    author: 'Gandhi',
    content: 'La non-violence, sous sa forme active, consiste en une bienveillance envers tout ce qui existe.',
    image: Gandhi,
  },
  GatheringFord: {
    author: 'Henri Ford',
    content: 'Se réunir est un début, rester ensemble est un progrès, travailler ensemble est la réussite.',
    image: HenriFord,
  },
}

export const Quote = ({ data, preset, style, styleTitle }) => {

  const { author, content, image } = preset ? preSets[preset] : data

  return (
    <Box sx={{ lineHeight: '1.8rem', display: 'flex', ml: '-10%', width: '120%' }} style={style}>
      <Box component='img' src={image} alt='avatar of quote author' sx={{ width: '150px', height: '140px' }} />
      <Box sx={{ textAlign: 'center', mr: 0 }}>
        <Typography variant='body1' fontSize='1.5rem' fontWeight='400' textAlign='left' paddingLeft='40px' ml={{ lg: '-3rem' }}>
          {content}
        </Typography>
        <Typography style={styleTitle} fontWeight='600' textAlign='left' paddingLeft='40px'>- {author} -</Typography>
      </Box>
    </Box>
  )
}

Quote.propTypes = {
  data: PropTypes.object,
  preset: PropTypes.string,
  style: PropTypes.object,
  styleTitle: PropTypes.object,
}

export default Quote
