import React from 'react'
import Logo from '../../assets/DIVERS/Boussole_logo_2019.png'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'


export const Loading = () => {

  const { t } = useTranslation()

  return (
    <Box sx={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}>
      <Box component='img' src={Logo} alt='Boussole' sx={{
        height: '100px',
        animation: 'rotate 5s linear 0s infinite',
        mb: '20px',
      }} />
      <Typography color='text.primary'>{t('common.loading')}</Typography>
    </Box>
  )
}

export default Loading
