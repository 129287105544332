import React, { useEffect, useState } from 'react'
import IntroTest from './IntroTest'
import FreemiumTestEnd from './FreemiumTestEnd'
import { HOME } from '../../_constants/routes'
import LoadingBoussole from '../_library/LoadingBoussole'
import QuestionMCQ from './QuestionMCQ'
import { ANSWER_COLLECTION, PROPOSAL_COLLECTION, QUESTION_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { Navigate } from 'react-router'
import Page from '../_library/Page'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


const TestPage = () => {
  
  const profile = useAuth().getProfile()
  const questionHooks = useFirestore(QUESTION_COLLECTION)
  const questions = questionHooks.getDocs()
  const answerHooks = useFirestore(ANSWER_COLLECTION)
  const proposalHooks = useFirestore(PROPOSAL_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [questionsOk, setQuestionsOk] = useState(false)
  
  useEffect(() => {
    questionHooks.listen({ orderBy: [['order', 'asc']] })
    return questionHooks.unsubscribe
  }, [profile.gender])
  
  useEffect(() => {
    answerHooks.listen({ where: [['userRef', '==', userHooks.getDocRef(profile.id)]] })
    return answerHooks.unsubscribe
  }, [profile.id])
  
  useEffect(() => {
    proposalHooks.listen({ orderBy: [['factor', 'asc']] })
    return proposalHooks.unsubscribe
  }, [])

  const getStepContent = () => {
    if (!profile.gender) return <IntroTest testLength={questions?.length} onSubmit={gender => userHooks.updateDoc(profile.id, { gender })} />
    else if (questionsOk && profile.isAnonymous) return <FreemiumTestEnd />
    else if (questionsOk && profile.profileType) return <Navigate to={HOME} />
    else if (questions) return <QuestionMCQ questions={questions} onFinish={() => setQuestionsOk(true)} />
    else return <LoadingBoussole />
  }

  return (
    <Page withTopBar sx={{ pt: '.1px', pb: 1, bgcolor: !questionsOk && 'background.deepBlue' }}>
      {getStepContent()}
    </Page>
  )
}

export default TestPage
