import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Avatar from '../../_library/Avatar'
import { Box, Typography } from '@mui/material'
import useFirestore from '../../../hooks/useFirestore'
import { EMAIL_COLLECTION } from '../../../_constants/globals'
import { resetNotifications } from '../../../store/actions/notificationActions'
import { INVITE_MEMBER_ERROR, INVITE_MEMBER_SUCCESS } from '../../../store/types'


const UserChip = ({ user, disableResendMail }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const emailHooks = useFirestore(EMAIL_COLLECTION)
  
  const backgroundColor = useMemo(() => {
    switch (user.onboardingStatus) {
      case 'emailConfirmed': return '#2D936C80'
      case 'done': return '#2D936C'
      case 'error': return 'secondary.main'
    }
  }, [user.onboardingStatus])

  const resendInviteTeamMemberAction = email =>
    emailHooks.addDoc({
      type: 'reInvite',
      to: email,
      origin: window.location.origin,
    }).then(() => {
      dispatch(resetNotifications)
      return dispatch({ type: INVITE_MEMBER_SUCCESS })
    }).catch(err => {
      dispatch(resetNotifications)
      return dispatch({ type: INVITE_MEMBER_ERROR, err })
    })

  return (
    <Box sx={{
      my: { xs: 4, md: 6 },
      mx: { xs: 1, md: 0 },
      position: 'relative',
      borderRadius: '15px',
      py: 1,
      pr: '20px',
      pl: '60px',
      border: user.onboardingStatus === 'waiting' && '2px solid',
      borderColor: user.onboardingStatus === 'waiting' && 'grey.100',
      backgroundColor,
    }}>
      <Avatar photoUrl={user.photoUrl} sx={{
        position: 'absolute',
        left: '-40px',
        top: '50%',
        mt: '-40px',
      }} />
      <Typography component='span' color='grey.100' mr={3}>{user.firstname}</Typography>
      {user.error
        ? <Box component='span' sx={{ float: 'right' }}>{t(`error.${user.error.code}`)}</Box>
        : <>
          <Box component='span' sx={{ float: 'right' }}>{t(`onboarding.status.${user.onboardingStatus || 'waiting'}`)}</Box>
          {user.onboardingStatus === 'waiting' && !disableResendMail && <Button onClick={() => resendInviteTeamMemberAction(user.email)} sx={{
            color: 'white',
            border: 'solid white 1px',
            fontSize: '.7rem',
            float: 'right',
            mr: 3,
            lineHeight: '1em',
            display: { xs: 'none',  lg: 'block' },
          }}>
            {t('team.resendEmailLink')}
          </Button>}
        </>
      }
    </Box>
  )
}

UserChip.propTypes = {
  user: PropTypes.object.isRequired,
  disableResendMail: PropTypes.bool,
}

export default UserChip
