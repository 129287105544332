import { createTheme } from '@mui/material/styles'
import RalewayTtf from './assets/fonts/Raleway/Raleway-Medium.ttf'

const palette = {
  primary: {
    light: '#3CADAC',
    main: '#3DADAD',
    dark: '#343051',
  },
  secondary: {
    main: '#F05',
    dark: '#B2003B',
  },
  tertiary: {
    lighter: '#F8ECCF',
    light: '#D99362',
    main: '#d17838',
    dark: '#BE4F23',
  },
  grey: {
    100: '#F8F0D7',
    150: '#EAEAEA',
    200: '#C8C8C8',
    300: '#9e9bac',
    700: '#464646',
  },
  background: {
    default: '#F8F8F8',
    blue: '#3DADAD',
    deepBlue: '#343051',
  },
  divider: '#343051',
  action: {
    active: '#ddd',
  },
  error: {
    main: '#F05',
  },
}

const theme = createTheme({
  palette,
  typography: {
    useNextVariants: true,
    fontFamily: ['Raleway'],
    h1: {
      color: palette.primary.main,
      fontSize: '3rem',
      fontWeight: '600',
      marginBottom: '50px',
    },
    h2: {
      fontSize: '2.3rem',
      fontWeight: '700',
      color: palette.primary.dark,
      marginBottom: '32px',
    },
    root: {
      color: 'inherit',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Raleway';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Raleway'), local('Raleway-Regular'), url(${RalewayTtf}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1rem',
        },
        contained: {
          boxShadow: 'none',
        },
        text: {
          color: palette.grey[100],
          fontWeight: 700,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          height: 'max-content',
        },
        item: {
          height: 'auto',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&::after': {
            border: '1px solid',
            borderColor: palette.primary.light,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: 200,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        animated: {
          '&.Mui-focused': {
            color: palette.primary.light,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
          color: 'black',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: 0,
          color: palette.primary.dark,
          '&:hover': {
            backgroundColor: 'inherit',
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #CCC',
          margin: 0,
          padding: '12px 70px 12px 0',
        },
        primary: {
          fontWeight: 600,
          fontSize: '1.5rem',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          marginTop: '8px',
          marginBottom: '8px',
        },
      },
    },
  },
})

export default theme
