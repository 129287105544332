import React from 'react'
import PropTypes from 'prop-types'
import bidenImage from '../../assets/CARDS/KIT1/team-map-biden-team.png'
import { Box } from '@mui/material'

const preSets = {
  bidenImage: {
    alt: 'Biden winning team 2020',
    image: bidenImage,
  },
}

export const ImageInCard = ({ data, preset }) => {

  const { alt, image } = preset ? preSets[preset] : data

  return <Box component='img' src={image} alt={alt} sx={{
    width: '500px',
    maxWidth: '100%',
    pt: '5px',
    pb: '20px',
  }} />
}

ImageInCard.propTypes = {
  data: PropTypes.object,
  preset: PropTypes.string,
}

export default ImageInCard
