import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import FirstLevelButton from '../_library/FirstLevelButton'
import { USER_COLLECTION } from '../../_constants/globals'
import OutlinedButton from '../_library/OutlinedButton'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


export const DeleteAccount = () => {
  
  const auth = useAuth()
  const profile = auth.getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [confirm, setConfirm] = useState(false)
  
  const handleClick = () => confirm ? userHooks.deleteDoc(profile.id).then(auth.signOut) : setConfirm(true)
  
  return (
    <Box sx={{
      width: { xs: '90%', md: '50%' },
      ml: { xs: '5%', md: '15%' },
      mb: 4,
      font: 'Raleway',
    }}>
      <Typography>C&apos;était chouette de vous compter parmi nous dans Boussole ! En supprimant votre compte, toutes vos données seront effacées définitivement. Attention, aucun retour en arrière sera possible :)</Typography>
      {confirm
        ? <>
          <Typography variant='body1' fontWeight='bold'>Êtes-vous certain de vouloir supprimer votre compte ?</Typography>
          <FirstLevelButton onClick={handleClick} sx={{ mb: 1 }}>Oui, je supprime</FirstLevelButton>
          <OutlinedButton onClick={() => setConfirm(false)} sx={{ mb: 1 }}>Non, j&apos;annule</OutlinedButton>
        </>
        : <FirstLevelButton onClick={handleClick}>Supprimer le compte</FirstLevelButton>
      }
    </Box>
  )
}

export default DeleteAccount
