import {
  RESET_NOTIFICATIONS, DELETE_NOTIFICATION, RESET_PASSWORD_EMAIL_SUCCESS, RESET_PASSWORD_EMAIL_ERROR,
  NOTIFICATION, LOGIN_ERROR, INVITE_MEMBER_SUCCESS, CREATE_TEAM_SUCCESS, UPDATE_TEAM_SUCCESS, UPLOAD_COMPLETE, SUCCESS, ERROR, WARNING, INFO, LOADING, INVITE_MEMBER_ERROR,
} from '../types'

export default (state = {}, action) => {
  switch (action.type) {

    case RESET_NOTIFICATIONS:
      return {}
    case DELETE_NOTIFICATION:
      return { ...state, [action.payload]: null }
    case NOTIFICATION:
      return { ...state, notification: action.payload }

    // AUTH
    case LOGIN_ERROR:
      if (action.payload?.code === 'auth/user-not-found')
        return { ...state, error: { severity: 'error', message: 'login.error.user-not-found' } }
      else if (action.payload?.code === 'auth/wrong-password')
        return { ...state, error: { severity: 'error', message: action.query === "SIGN_IN_WITH_TEMPORARY_PASSWORD" ? 'login.error.wrong-tmp-password' : 'auth.error.wrong-password' } }
      else if (action.payload?.code === 'auth/user-token-expired')
        return { ...state, error: { severity: 'error', message: 'login.error.token-expired' } }
      else if (action.payload?.code === 'auth/argument-error')
        return { ...state, error: { severity: 'error', message: 'login.error.invalid-email' } }
      else if (action.payload?.code === 'auth/email-already-in-use')
        return { ...state, error: { severity: 'error', message: 'login.error.auth/email-already-in-use' } }
      else if (action.payload?.code === 'auth/provider-already-linked')
        return { ...state, error: { severity: 'error', message: 'auth/provider-already-linked' } }
      else {
        return { ...state, error: { severity: 'error', message: action.payload?.message || 'error.login' } }
      }
    case RESET_PASSWORD_EMAIL_SUCCESS:
      return { ...state, error: { severity: 'success', message: 'notification.emailResetSend' } }
    case RESET_PASSWORD_EMAIL_ERROR:
      return { ...state, error: { severity: 'error', message: 'login.error.invalid-email' } }

    case INVITE_MEMBER_SUCCESS:
      return { ...state, error: { severity: 'success', message: 'notification.inviteMemberSuccess' } }
    case INVITE_MEMBER_ERROR:
      return { ...state, error: { severity: 'error', message: action.payload?.message || 'notification.inviteMemberError' } }

    case CREATE_TEAM_SUCCESS:
      return { ...state, error: { severity: 'success', message: 'notification.createTeamSuccess' } }

    case UPDATE_TEAM_SUCCESS:
      return { ...state, error: { severity: 'success', message: 'notification.updateTeamSuccess' } }

    case UPLOAD_COMPLETE:
      return { ...state, error: { severity: 'success', message: 'notification.updateAvatarSuccess' } }

    case SUCCESS:
      return { ...state, misc: { severity: 'success', message: action.payload } }

    case ERROR:
      return { ...state, misc: { severity: 'error', message: action.payload } }

    case WARNING:
      return { ...state, misc: { severity: 'warning', message: action.payload } }

    case INFO:
      return { ...state, misc: { severity: 'info', message: action.payload } }

    case LOADING:
      return { ...state, misc: { severity: 'loading', message: action.payload } }

    default:
      return state
  }
}
