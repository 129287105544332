import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { POSITION, TALENT_EXT, TEAM_PROFILE } from '../../_constants/cardTypes'
import RatingComponent from '../cards/RatingComponent'
import StarsUnderCard from './StarsUnderCard'
import Loading from '../_library/Loading'
import SumUp from './SumUp'
import Sources from '../cards/Sources'
import { useNavigate, useLocation } from 'react-router-dom'
import Balloon, { BACKDROP_TYPE } from './Balloon'
import Quote from './Quote'
import ImageInCard from './ImageInCard'
import { renderToString } from 'react-dom/server'
import PersonalityGraph from '../_library/PersonalityGraph'
import { HOME, PRICING } from '../../_constants/routes'
import blurryGraphImg from '../../assets/DIVERS/blurry-graph.jpeg'
import CTAButton from '../_library/FirstLevelButton'
import FamousAvatars from './FamousAvatars'
import CardHeader from './CardHeader'
import { Box, Typography } from '@mui/material'
import Page from '../_library/Page'
import { useScroll } from 'framer-motion'
import StickyScrollBtn from '../_library/StickyScrollBtn'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'
import { USER_COLLECTION } from '../../_constants/globals'
import useCards from '../../hooks/useCards'


const CardPage = ({ type }) => {
  
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  const users = userHooks.getDocs()
  const navigate = useNavigate()
  const location = useLocation()
  const pageContentRef = useRef(null)
  const { scrollYProgress } = useScroll({ container: pageContentRef })
  const selectedUserId = useSelector(state => state.data.selectedUserId)
  const { cards, readCard, drawNextCard } = useCards()
  
  const [showBalloonExtCard, setShowBalloonExtCard] = useState(true)
  const [scrollProgress, setScrollProgress] = useState(0)
  
  const teamMembers = useMemo(() => users?.filter(u => u.teamRefs?.some(t => t.id === profile.currentTeamSelectedRef?.id) && u.id !== profile.id), [users, profile])
  
  const card = useMemo(() => {
    if (!cards) return null
    else if (type === TALENT_EXT) return cards.find(c => c.type === type && c.userRef.id === location.pathname.split('/').pop())
    else return cards.find(c => c.type === type)
  }, [cards, type, location.pathname, profile.id])
  
  useEffect(() => {
    if (card) {
      console.log(`card id ${card.id}`)
      if (type !== TEAM_PROFILE) readCard(card.id)
      drawNextCard(card.id)
    }
  }, [type, card?.id])
  
  useEffect(() => {scrollYProgress.on('change', setScrollProgress)}, [scrollYProgress])
  
  if (!card) return <Loading />
  const { subtitle, title, intro, user, userRef, sumUp, sources, article } = card
  return (
    <Page withTopBar ref={pageContentRef}>
      <Box sx={{ pb: '140px' }}>
        <CardHeader title={title} subtitle={subtitle} type={type} avatarUrl={user.photoUrl} />
        <Box sx={{
          mx: { xs: 1, sm: 'auto' },
          pl: { xs: 0, sm: '11%' },
          textAlign: 'left',
          width: { xs: 'inherit', md: '60vw' },
          maxWidth: { xs: '100%', sm: '700px' },
          position: 'relative',
          color: 'black',
          fontSize: '.9rem',
          lineHeight: '2rem',
        }}>
          <Box sx={{ display: { xs: 'flex', sm: 'block' }, flexDirection: { xs: 'column-reverse', sm: 'initial' } }}>
            <Box sx={{
              pr: { xs: 'inherit', md: '45px' },
              maxWidth: { xs: 'inherit', md: '300px' },
              position: { xs: 'inherit', md: 'absolute' },
              left: { xs: 'inherit', md: '-14vw' },
              top: { xs: 'inherit', md: '260px' },
            }}>
              {type === TALENT_EXT  && userRef.id !== profile.id &&
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <StarsUnderCard firstname={card.user.firstname} rating={card.rating} comment={card.comment} />
                </Box>
              }
            </Box>
            <div>
              {intro && <Typography fontSize='1.3rem' fontWeight={600} lineHeight='2.5rem' pt={{ xs: 0, md: '2rem' }}>{intro}</Typography>}
              {type === POSITION && profile.companyRef && !selectedUserId &&
                <PersonalityGraph users={[profile]} displayHeart displayWeb />
              }
              {type === POSITION && selectedUserId && users &&
                <PersonalityGraph users={users.filter(u => u.id === selectedUserId)} displayHeart displayWeb />
              }
              {type === TALENT_EXT && profile.companyRef &&
                <PersonalityGraph users={[userRef.id === profile.id ? profile : teamMembers?.find(u => u.id === userRef.id)]} displayHeart displayWeb />
              }
            </div>
          </Box>
          <Box sx={{
            my: '0.9rem',
            lineHeight: '2rem',
            fontSize: '1.15em',
            '& h3': {
              color: theme => theme.palette.tertiary?.dark,
              marginBottom: '0.4rem',
              fontSize: '1.4rem',
              fontWeight: 800,
              marginTop: '2rem',
            },
            '& ul': {
              listStylePosition: 'inside',
              pl: 0,
            },
            '& li': {
              fontSize: '1rem',
              fontWeight: 700,
            },
          }}>
            {article && parse(article
              .replace('FIRSTNAME', user?.firstname || 'firstname')
              .replace('BIDEN_IMAGE', renderToString(<ImageInCard preset='bidenImage' />))
              .replace('NONVIOLENCE_GANDHI', renderToString(<Quote preset='nonViolenceGandhi' />))
              .replace('GATHERING_FORD', renderToString(<Quote preset='GatheringFord' />)),
            )}
          </Box>
          {sumUp && <SumUp list={sumUp} />}
          {sources && <Sources sources={sources} />}
          {type === TALENT_EXT && <FamousAvatars profileType={user.profileType} />}
          {type === POSITION && !profile.companyRef && <Box sx={{ position: 'relative' }}>
            <Box component='img' src={blurryGraphImg} alt='blurry graph' sx={{ width: '100%' }} />
            <CTAButton onClick={() => navigate(PRICING)} sx={{
              bgcolor: 'primary.light',
              height: '44px',
              width: '284px',
              position: 'absolute',
              top: 'calc(50% - 30px)',
              left: 'calc(50% - 142px)',
              fontSize: '1rem',
            }}>Débloquer la version intégrale</CTAButton>
          </Box>}
          {type === TALENT_EXT && userRef.id === profile.id
            ? card && <RatingComponent card={card} next={() => navigate(HOME)} />
            : <StickyScrollBtn onClick={() => navigate(HOME)} pageScrollPercent={scrollProgress} disabled={profile.companyRef?.id !== card.companyRef?.id}>
              Carte suivante
            </StickyScrollBtn>
          }
          {type === TALENT_EXT && userRef.id === profile.id && !card.rating && showBalloonExtCard && <Balloon
            preset='goldTalentSummary'
            onCTA={() => setShowBalloonExtCard(false)}
            backdropType={BACKDROP_TYPE.transparentGreen}
          />}
        </Box>
      </Box>
    </Page>
  )
}

CardPage.propTypes = {
  type: PropTypes.string.isRequired,
}

export default CardPage
