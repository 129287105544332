import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Nav from '../Nav'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'


const Page = ({ children, withTopBar, sx, containerSX, scrollRef }) => {
  
  const consultantMode = useSelector(state => state.data.consultantMode)
  
  return (
    <Box sx={{ height: '100vh', ...sx }}>
      {withTopBar && !consultantMode && <Nav />}
      <Box ref={scrollRef} sx={{ height: withTopBar && !consultantMode ? 'calc(100vh - 80px)' : '100%', overflowY: 'auto', ...containerSX }}>
        {children}
      </Box>
    </Box>
  )
}

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  withTopBar: PropTypes.bool,
  sx: PropTypes.object,
  containerSX: PropTypes.object,
  scrollRef: PropTypes.object,
}

// eslint-disable-next-line react/display-name
export default forwardRef((props, ref) => <Page {...props} scrollRef={ref} />)
