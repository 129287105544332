import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Button, Box } from '@mui/material'
import { Done as DoneIcon } from '@mui/icons-material'
import useFirestore from '../../hooks/useFirestore'
import { TEAM_COLLECTION } from '../../_constants/globals'


export const TeamChipEdit = ({ team }) => {
  
  const teamHooks = useFirestore(TEAM_COLLECTION)
  
  const [teamName, setTeamName]= useState(team.name || '')
  
  const handleSubmit = event => {
    event.preventDefault()
    teamHooks.updateDoc(team.id, { name: teamName })
  }
  
  return (
    <Box sx={{ my: 2, mx: 1, p: 1, pl: '40px' }}>
      <Box component='form' onSubmit={handleSubmit} noValidate autoComplete='off'>
        <TextField
          name='firstname'
          value={teamName}
          onChange={event => setTeamName(event.target.value)}
          inputProps={{ sx: { color: 'grey.100' } }}
          sx={{
            '& .MuiInput-underline:before': {
              borderBottomColor: 'grey.100',
            },
            '& .MuiInput-underline:hover:before': {
              borderBottomColor: 'grey.100',
            },
          }}
        />
        {team.name !== teamName && <Button type='submit' sx={{ color: 'grey.100' }}><DoneIcon /></Button>}
      </Box>
    </Box>
  )
}

TeamChipEdit.propTypes = {
  team: PropTypes.object.isRequired,
}

export default TeamChipEdit
