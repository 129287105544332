import React from 'react'
import { styled } from '@mui/material/styles'
import { Checkbox } from '@mui/material'


const StyledComponent = styled(Checkbox)({
  padding: 0,
  paddingRight: 5,
  color: '#f5e873',
  '&$checked': {
    color: '#f5e873',
  },
})

export const GreenCheckbox = (props => <StyledComponent color='default' {...props} />)

export default GreenCheckbox
