import React from 'react'
import PropTypes from 'prop-types'
import { Typography, FormControlLabel, Box, FormGroup } from '@mui/material'
import GreenCheckbox from '../_library/GreenCheckbox'
import { shallowEqual, useSelector } from 'react-redux'


export const Topics = ({ teammate, selectedTopics, setSelectedTopics }) => {
  
  const topics = useSelector(state => state.firestore.oneOneTopics, shallowEqual)
  
  return (
    <Box sx={{ pt: 2 }}>
      <Typography fontWeight='700'>Sélectionnez les sujets dont vous voudriez parler :</Typography>
      <FormGroup>
        {topics?.filter(t => t.slug !== 'custom-questions').map(topic =>
          <FormControlLabel
            key={`filteredTopic${topic.id}`}
            control={<GreenCheckbox name='checkedG' checked={selectedTopics.some(c => c.id === topic.id)} />}
            onChange={event => setSelectedTopics(prev => event.target.checked
              ? topics.filter(q => prev.some(p => p.id === q.id) || topic.id === q.id)
              : prev.filter(c => c.id !== topic.id),
            )}
            label={<Typography fontWeight='normal' lineHeight='1.5rem' my='.7rem'>{topic?.text?.fr?.replace('FIRSTNAME', teammate.firstname) || 'Catégorie sans nom'}</Typography>}
            sx={{ mx: 0 }}
          />,
        )}
      </FormGroup>
    </Box>
  )
}

Topics.propTypes = {
  teammate: PropTypes.object.isRequired,
  selectedTopics: PropTypes.array.isRequired,
  setSelectedTopics: PropTypes.func.isRequired,
}

export default Topics
