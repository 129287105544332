import React, { useMemo, useState } from 'react'
import { Box, Button, Skeleton, Typography } from '@mui/material'
import UserChip from './UserChip'
import UserChipEdit from './UserChipEdit'
import { ACCESS_LEVEL_ADMIN, TEAM_COLLECTION, USER_COLLECTION } from '../../../_constants/globals'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../hooks/useAuth'
import useFirestore from '../../../hooks/useFirestore'


export const DisplayTeamPanel = () => {

  const profile = useAuth().getProfile()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHooks.getDocs()
  const { t } = useTranslation()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()

  const [edit, setEdit]= useState(false)
  
  const team = useMemo(() => dbTeams?.find(t => t.id === profile.currentTeamSelectedRef?.id, [dbTeams, profile]))
  
  const members = useMemo(() => dbUsers?.filter(u => u.teamRefs?.some(t => t.id === profile.currentTeamSelectedRef?.id)), [dbUsers, profile])

  const membersWithRoles = useMemo(() => members?.filter(u => u.id !== profile.id).map(user => ({
    ...user,
    role: team?.managerRefs.some(ref => ref.id === user.id) ? 'teamManager' : 'member',
  })), [members, team, profile.id])

  const isManagerOfThisTeam = team?.managerRefs.some(ref => ref.id === profile.id)
  
  return (
    <Box sx={{ color: 'white', my: '5%', mx: 'auto', maxWidth: '600px' }}>
      <Typography variant='h1' fontSize='2.5rem' color='white'>{team?.name}</Typography>
      {isManagerOfThisTeam || profile.accessLevel >= ACCESS_LEVEL_ADMIN
        ? <Button variant='text' onClick={() => setEdit(prevState => !prevState)} sx={{ float: 'right' }}>
          {edit ? t('common.save') : t('team.edit')}
        </Button>
        : <Typography>{t('team.not-team-manager')}</Typography>
      }
      <Box mt={7} px={5}>
        {membersWithRoles?.length > 0
          ? membersWithRoles.map(user => edit
            ? <UserChipEdit user={user} key={user.id} />
            : <UserChip user={user} key={user.id} disableResendMail={!isManagerOfThisTeam} />,
          )
          : <>
            <StyledSkeleton sx={{ mt: '20%', opacity: .4 }} />
            <StyledSkeleton sx={{ opacity: .3 }} />
            <StyledSkeleton sx={{ opacity: .2 }} />
            <StyledSkeleton sx={{ opacity: .1 }} />
          </>
        }
      </Box>
    </Box>
  )
}

// eslint-disable-next-line react/prop-types
const StyledSkeleton = ({ sx }) => <Skeleton variant='rounded' animation={false} width='100%' height={60} sx={{
  border: 'solid 2px',
  borderRadius: '20px',
  bgcolor: '#ffffff55',
  mb: 5,
  ...sx,
}} />

export default DisplayTeamPanel
