import React from 'react'
import PropTypes from 'prop-types'
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer'

Font.register({ family: 'Raleway', src: '../Raleway/Raleway-Regular.ttf' })

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    marginBottom: 7,
    marginRight: 5,
    marginLeft: 5,
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
  itemContent: {
    flex: 1,
    fontSize: 8,
    fontFamily: 'Raleway',
    textAlign: 'left',
  },
})

const List = ({ children }) => children

export const Item = ({ children }) => (
  <View style={styles.item}>
    <Text style={styles.bulletPoint}>•</Text>
    <Text style={styles.itemContent}>{children}</Text>
  </View>
)

List.propTypes ={
  children: PropTypes.array,
}

Item.propTypes ={
  children: PropTypes.string.isRequired,
}

export default List
