import React, { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import logo from '../assets/DIVERS/logo-boussole.png'
import { HOME } from '../_constants/routes'
import { Link, useTheme, useMediaQuery, Box } from '@mui/material'
import MyMenu from './_library/MyMenu'
import * as ROUTES from '../_constants/routes'
import useAuth from '../hooks/useAuth'
import { useTranslation } from 'react-i18next'


export const Nav = () => {
  
  const profile = useAuth().getProfile()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()
  
  const logoUrl = useMemo(() => profile?.consultantCompany?.logoUrl, [profile])
  
  const logoStyle = {
    margin: { xs: '5px', sm: '15px' },
    height: '50px',
    maxWidth: { xs: '67%', sm: '100%' },
    maxHeight: { xs: '94%', sm: '100%' },
  }
  
  return (
    <>
      <Box sx={{
        height: '80px',
        top: 0,
        backgroundColor: 'white',
        width: '100%',
        display: { xs: 'none', md: 'block' },
      }} />
      <Box sx={{
        height: '80px',
        top: 0,
        backgroundColor: 'white',
        width: '100%',
        zIndex: 10,
        position: { md: 'fixed' },
        boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.08)',
      }}>
        <Link onClick={() => navigate(HOME)}>
          {logoUrl && <Box component='img' src={logoUrl} alt='Consultant Logo' sx={logoStyle} />}
          <Box component='img' src={logo} alt='logo' sx={logoStyle} />
        </Link>
        <MyMenu />
        {isMdUp &&
          (location.pathname.includes('/card/')
            || location.pathname.includes(ROUTES.PROFILE)
            || location.pathname.includes(ROUTES.TEAM)
            || location.pathname.includes(ROUTES.USERS)
            || location.pathname.includes(ROUTES.PRICING)
            || location.pathname.includes('/campfire/'))
          && <Box sx={{
            float: 'right',
            height: 80,
            lineHeight: '80px',
            textAlign: 'center',
            fontSize: 'larger',
            mr: '20px',
            my: 0,
            fontWeight: 'bold',
          }}>
            {profile.profileType
              ? <Link onClick={() => navigate(HOME)} sx={{ color: 'grey.300' }}>&lt; {t('common.myCards')}</Link>
              : <Link onClick={() => navigate(-1)} sx={{ color: 'grey.300' }}>&lt; {t('common.back')}</Link>
            }
          </Box>}
      </Box>
    </>
  )
}

export default Nav
