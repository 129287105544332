import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar, IconButton, Alert } from '@mui/material'
import { Close } from '@mui/icons-material'
import { deleteNotification } from '../store/actions/notificationActions'
import { toPairs } from 'lodash'
import { useTranslation } from 'react-i18next'


const Notifications = () => {

  const notifications = useSelector(state => state.notifications)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return toPairs(notifications).map(([key, notif]) => notif &&
    <Snackbar
      key={key}
      open
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={notif.message}
      autoHideDuration={notif.autoHideDuration || null}
      onClose={() => dispatch(deleteNotification(key))}
      action={(
        <IconButton size='small' aria-label='close' color='inherit' onClick={() => dispatch(deleteNotification(key))}>
          <Close fontSize='small' />
        </IconButton>
      )}
    >
      <Alert elevation={6} variant='filled' severity={notif.severity || 'info'} onClose={() => dispatch(deleteNotification(key))}>
        {t(notif.message)}
      </Alert>
    </Snackbar>,
  )
}

export default Notifications
