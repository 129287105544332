import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'


const WelcomeComponent = () => {
  
  const { t } = useTranslation()
  const profile = useAuth().getProfile()

  return (
    <Box pt={1} m='70px 15% 10px'>
      <Typography variant='h3' fontWeight={600} fontSize='1.8rem'>{t('onboarding.welcome-title').replace('FIRSTNAME', profile.firstname)}</Typography>
      <Typography variant='h1' mb={5}>{t('onboarding.firstSteps-title')}</Typography>
      <Typography fontSize='1.2rem'>{t(`onboarding.picture-paragraph.${profile.gender || 'man'}`)}</Typography>
    </Box>
  )
}

export default WelcomeComponent
