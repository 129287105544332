export const HOME = '/'
export const PROFILE = '/profile'
export const CGU = '/profile/cgu'
export const RGPD = '/profile/rgpd'
export const TEAM = '/team'
export const TEAMS = '/teams'
export const LOGIN = '/login'
export const ONBOARDING = '/onboarding'
export const TEST = '/test'
export const CARD = '/card'
export const FREEMIUM = '/freemium'
export const PRICING = '/pricing'
export const AVATAR = '/avatar'
export const PAYMENT_SUMMARY = '/payment/summary/:number'
export const USERS = '/users'
export const CAMPFIRE = '/campfire/:id'
