import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LOGIN } from '../../_constants/routes'
import CTAButton from '../_library/FirstLevelButton'
import { useNavigate } from 'react-router-dom'
import { REGEX_PWD } from '../../_constants/regex'
import useAuth from '../../hooks/useAuth'
const PREFIX = 'ResetPasswordForm'


const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  newPassword: `${PREFIX}-newPassword`,
}

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.input}`]: {
    marginBottom: theme.spacing(2),
    '& label': {
      color: theme.palette.primary.dark,
    },
    '& input': {
      '&::after': {
        border: '1px solid',
        borderColor: theme.palette.primary.light,
      },
    },
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '90%',
      height: '3.5rem',
      marginLeft: '0.8rem',
    },
  },

  [`& .${classes.newPassword}`]: {
    [theme.breakpoints.down('sm')]: {
      height: 40,
    },
  },
}))

export const ResetPasswordForm = ({ oobCode }) => {
  
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = useAuth()

  const [password, setPassword] = useState(null)
  const [isSubmitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (password && !password.match(REGEX_PWD))
      setErrors(prev => ({ ...prev, password: t('login.error.password-length') }))
    return () => setErrors({})
  }, [password, t])

  const enableBtn = useMemo(() => password && Object.keys(errors).length === 0 && !isSubmitted, [password, errors, isSubmitted])

  const handleSubmitSignIn = e => {
    e.preventDefault()
    setSubmitted(true)
    auth.resetPasswordAction(oobCode, password)
      .then(() => navigate(LOGIN))
      .finally(() => setSubmitted(false))
  }

  return (
    <Root onSubmit={handleSubmitSignIn} noValidate>
      <TextField
        className={classes.input}
        name='password'
        type='password'
        label={t('login.temporary-password.new-password')}
        value={password || ''}
        onChange={e => setPassword(e.target.value)}
        fullWidth
        color='primary'
        disabled={isSubmitted}
        error={!!errors.password}
        helperText={errors.password}
        autoFocus
      />
      <CTAButton type='submit' className={classes.button} disabled={!enableBtn}>
        {t('common.submit')}
      </CTAButton>
    </Root>
  )
}

ResetPasswordForm.propTypes = {
  oobCode: PropTypes.string.isRequired,
}

export default ResetPasswordForm
