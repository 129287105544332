import React from 'react'
import { Button, styled } from '@mui/material'


const ThirdLevelButton = styled(Button)(({ theme }) => ({
  padding: '.5rem 1.5rem',
  textTransform: 'initial',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  backgroundColor: 'unset',
  '&:hover': {
    background: theme.palette.primary.main,
    color: 'white',
  },
  '&.MuiButton-contained.Mui-disabled': {
    backgroundColor: 'unset',
    color: theme.palette.primary.main,
    opacity: .5,
  },
}))

// eslint-disable-next-line react/display-name
export default props => <ThirdLevelButton {...props} />
