import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Question from './Question'
import LoadingBoussole from '../_library/LoadingBoussole'
import { ANSWER_COLLECTION, PROPOSAL_COLLECTION, QUESTION_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'
import { useTranslation } from 'react-i18next'


export const QuestionMCQ = ({ questions, onFinish }) => {

  const profile = useAuth().getProfile()
  const answerHooks = useFirestore(ANSWER_COLLECTION)
  const answers = answerHooks.getDocs() || []
  const questionHooks = useFirestore(QUESTION_COLLECTION)
  const proposalHooks = useFirestore(PROPOSAL_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const { i18n } = useTranslation()

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null)
  const [question, setQuestion] = useState(null)
  const [answer, setAnswer] = useState(null)

  const done = useMemo(() => questions.length === answers.length && !selectedQuestionIndex, [questions, answers, selectedQuestionIndex])

  useEffect(() => {
    if (done) onFinish()
  }, [done])

  useEffect(() => {
    if (selectedQuestionIndex || selectedQuestionIndex === 0)
      setQuestion(questions[selectedQuestionIndex])
    else
      setQuestion(questions[answers.length])
    return () => setQuestion(null)
  }, [selectedQuestionIndex, setQuestion, answers, questions])

  useEffect(() => {
    if (question) setAnswer(answers.find(ans => ans.questionRef.id === question.id))
    return () => setAnswer(null)
  }, [question, answers, setAnswer])

  const previous = () => {
    if (selectedQuestionIndex === 0 || answers.length === 0)
      userHooks.updateDoc(profile.id, { gender: null })
    else
      setSelectedQuestionIndex(prev => prev ? prev - 1 : answers.length - 1)
  }

  const questionText = useMemo(() => {
    if (i18n?.language.slice(0, 2) === 'fr') return question?.text.fr[profile.gender]
    else return question?.text.en[profile.gender]
  }, [question, i18n?.language, profile.gender])

  const next = async proposal => {
    const obj = {
      questionRef: questionHooks.getDocRef(question.id),
      proposalRef: proposalHooks.getDocRef(proposal.id),
      userRef: userHooks.getDocRef(profile.id),
    }
    answer?.id
      ? await answerHooks.updateDoc(answer.id, obj)
      : await answerHooks.addDoc(obj)
    selectedQuestionIndex && selectedQuestionIndex < answers.length
      ? setSelectedQuestionIndex(prev => prev + 1)
      : setSelectedQuestionIndex(null)
  }

  if (!question) return <LoadingBoussole />
  return question && <Question
    question={questionText || question.text.en.man}
    num={question.order}
    total={questions.length}
    goBack={previous}
    next={next}
    answer={answer}
  />
}

QuestionMCQ.propTypes = {
  questions: PropTypes.array.isRequired,
  onFinish: PropTypes.func.isRequired,
}

export default QuestionMCQ
