import React from 'react'
import PropTypes from 'prop-types'
import { PRICING } from '../../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'


export const InvitationDisplay = ({ amount }) => {

  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Box onClick={() => navigate(PRICING)} sx={{
      cursor: 'pointer',
      p: 1,
      bgcolor: amount === 0 ? '#ff0354' : '#f9edcf',
      color: amount === 0 ? '#f8eccf' : '#d37c3d',
      float: { md: 'right' },
      width: { xs: '100%', md: 'fit-content' },
      textAlign: 'center',
    }}>
      {t('pricing.licences', { count: amount })}
    </Box>
  )
}

InvitationDisplay.propTypes = {
  amount: PropTypes.number.isRequired,
}

export default InvitationDisplay
