import React, { useMemo } from 'react'
import ExplainScreen from './ExplainScreen'
import TalentScreen from './TalentScreen'
import LetsGoScreen from './LetsGoScreen'
import { Navigate } from 'react-router-dom'
import { USER_COLLECTION } from '../../_constants/globals'
import { TEST } from '../../_constants/routes'
import Page from '../_library/Page'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


const OnboardingPage = () => {

  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const content = useMemo(() => {
    const goBack = () => userHooks.updateDoc(profile.id, { onboardingStep: profile.onboardingStep - 1 })
    const goFore = () => userHooks.updateDoc(profile.id, {
      onboardingStep: (profile.onboardingStep || 0) + 1,
      onboardingStatus: profile.onboardingStep >= 2 ? 'done' : 'onboarding',
    })
    switch (profile.onboardingStep) {
      case 0:
        return <ExplainScreen goFore={goFore} />
      case 1:
        return <TalentScreen goFore={goFore} goBack={goBack} />
      case 2:
        return <LetsGoScreen goFore={goFore} goBack={goBack} />
      case 3:
        return <Navigate replace to={TEST} />
      default:
        return <ExplainScreen goFore={goFore} />
    }
  }, [profile])
  
  return (
    <Page withTopBar>
      {content}
    </Page>
  )
}

export default OnboardingPage
