import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { TALENT_EXT } from '../../_constants/cardTypes'
import photoIcon from '../../assets/DIVERS/avatar_team_member.svg'
import { getImage } from '../../_helpers/cardHelper'
import useAuth from '../../hooks/useAuth'


const PREFIX = 'CardHeader'

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  imageAndSubtitle: `${PREFIX}-imageAndSubtitle`,
  subtitle: `${PREFIX}-subtitle`,
  title: `${PREFIX}-title`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
    paddingTop: 80,
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.background.blue,
      paddingTop: '10%',
    },
  },

  [`& .${classes.content}`]: {
    margin: '0 auto',
    paddingLeft: '11%',
    textAlign: 'left',
    maxWidth: 700,
    position: 'relative',
    color: 'black',
    fontSize: '0.9rem',
    lineHeight: '2rem',
    [theme.breakpoints.up('md')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      margin: theme.spacing(0, 1),
      paddingLeft: 0,
    },
  },

  [`& .${classes.imageAndSubtitle}`]: {
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  
  [`& .${classes.subtitle}`]: {
    display: 'flex',
    color: theme.palette.common.white,
    fontSize: '1.4rem',
    fontWeight: 1000,
    fontStyle: 'italic',
    fontFamily: 'Raleway',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
    },
  },

  [`& .${classes.title}`]: {
    color: 'white',
    marginBottom: '0.5rem',
    minHeight: '2.5rem',
    fontSize: '3.5rem',
    fontFamily: 'Raleway',
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
      lineHeight: '3rem',
      clear: 'both',
    },
  },
}))

const cardStyle = {
  maxWidth: '236px',
  position: { xs: 'relative', md: 'absolute' },
  left: { md: '-14vw' },
  display: { xs: 'flex', md: 'block' },
  width: { xs: '30vw', md: 'initial' },
}

const CardHeader = ({ title, subtitle, type, avatarUrl, backgroundUrl }) => {
  
  const profile = useAuth().getProfile()

  const displayedTitle = useMemo(() => profile && title?.replace(/FIRSTNAME/g, profile.firstname), [title, profile])

  return (
    <Root className={classes.root}>
      <div className={classes.content}>
        <div className={classes.imageAndSubtitle}>
          {type === TALENT_EXT
            ? <Box sx={cardStyle}>
              <Box component='img' src={getImage(TALENT_EXT)} alt={`card ${TALENT_EXT}`} sx={{ width: '100%' }} />
              <Box sx={{
                top: '28%',
                left: '18%',
                width: '64.5%',
                height: '40%',
                overflow: 'hidden',
                borderRadius: '100px',
                backgroundSize: 'cover',
                position: 'absolute',
                backgroundImage: `url('${avatarUrl || photoIcon}')` }}
              />
            </Box>
            : <Box sx={cardStyle}>
              <Box component='img' src={backgroundUrl || getImage(type)} alt={`card ${type}`} sx={{ width: '100%' }} />
            </Box>
          }
          {subtitle && <Typography variant='h2' className={classes.subtitle}>{subtitle}</Typography>}
        </div>
        {title && <Typography variant='h1' className={classes.title}>{displayedTitle}</Typography>}
      </div>
    </Root>
  )
}

CardHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  avatarUrl: PropTypes.string,
  backgroundUrl: PropTypes.string,
}

export default CardHeader
