export const REGEX_MAIL = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x21\x23-\x5b\x5d-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x21-\x5a\x53-\x7f])+)\])/

export const INVALID_PRO_MAIL_EXTENSIONS =
  ['@gmail.', '@yahoo.', '@free.fr', '@orange.fr', '@outlook.', '@live.',
    '@icloud.', '@aol.', '@hotmail.', '@sfr.fr', '@wanadoo.', '@laposte.',
    '@mac.', '@numericable.fr', '@mgx.fr', '@protonmail.com', '@me.com',
    '@bbox.fr', '@ymail.com', '@netcourrier.com', '@neuf.fr', '@msn.com',
    '@mail.com', '@ntymail.com']

/**
 * Matches passwords with :
 * - More than 8 characters
 * - At least one lower case character
 * - At least one upper case character
 * - At least one number
 * - At least one special character
 */
export const REGEX_PWD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/
