import React, { useMemo, useState } from 'react'
import { Box, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Route, Routes, useNavigate, useMatch } from 'react-router-dom'
import MyAccountForm from './MyAccountForm'
import PersonnalData from './PersonalData'
import CGU from './CGU'
import { ChevronLeft } from '@mui/icons-material'
import { PROFILE } from '../../_constants/routes'
import { Navigate } from 'react-router'
import Page from '../_library/Page'
import DeleteAccount from './DeleteAccount'
import useAuth from '../../hooks/useAuth'


const navItemStyle = {
  display: 'block',
  textDecoration: 'none',
  color: { xs: 'primary.dark', md: 'primary.main' },
  fontSize: '1.6rem',
  fontWeight: 800,
  mb: 5,
  '&.active': {
    color: theme => theme.palette.tertiary?.main,
  },
  '&:hover': {
    color: theme => theme.palette.tertiary?.main,
  },
}

export const ProfilePage = () => {
  
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const match = useMatch(PROFILE + '/:route')
  const profile = useAuth().getProfile()
  
  const [pageTitle, setPageTitle] = useState('Mon compte')
  const [menuOpen, setMenuOpen] = useState(!isUpMd)
  
  const matchRoute = useMemo(() => isUpMd && match?.params.route, [isUpMd, match])
  
  const clickHandler = route => {
    setMenuOpen(false)
    switch (route) {
      case 'account':
        setPageTitle('Mon compte')
        break
      case 'rgpd':
        setPageTitle('Confidentialité')
        break
      case 'cgu':
        setPageTitle('CGU')
        break
      case 'delete':
        setPageTitle('Supprimer le compte')
        break
    }
    return navigate(route)
  }
  
  return (
    <Page withTopBar containerSX={{ overflow: 'hidden' }}>
      <Typography variant='h1' ml={{ xs: '40px', sm: '15%' }} fontSize={{ xs: '2.5rem', sm: '3rem' }} my={2} position='fixed'>
        {!menuOpen && !isUpMd && <ChevronLeft
          onClick={() => {setMenuOpen(true); setPageTitle('Paramètres')}}
          sx={{ fontSize: '3rem', verticalAlign: 'sub' }}
        />}
        {pageTitle}
      </Typography>
      <Box id='leftPanel' sx={{
        pl: '10%',
        display: menuOpen || isUpMd ? 'block' : 'none',
        width: isUpMd ? '33%' : menuOpen ? '100%' : 0,
        borderRight: isUpMd && '3px solid rgb(177, 175, 175)',
        height: '75%',
        position: 'fixed',
        mt: '90px',
      }}>
        <Link onClick={() => clickHandler('account')} sx={navItemStyle} className={matchRoute === 'account' ? 'active' : ''}>Mon compte</Link>
        <Link onClick={() => clickHandler('rgpd')} sx={navItemStyle} className={matchRoute === 'rgpd' ? 'active' : ''}>Confidentialité</Link>
        <Link onClick={() => clickHandler('cgu')} sx={navItemStyle} className={matchRoute === 'cgu' ? 'active' : ''}>CGU</Link>
        {!profile.companyRef && <Link onClick={() => clickHandler('delete')} sx={navItemStyle} className={matchRoute === 'delete' ? 'active' : ''}>Supprimer le compte</Link>}
      </Box>
      <Box id='rightPanel' sx={{
        overflowY: 'auto',
        minWidth: { xs: '90%', md: 'initial' },
        width: isUpMd ? '66%' : menuOpen ? 0 : '100%',
        ml: isUpMd ? '33%' : 0,
        height: 'calc(100% - 80px)',
        visibility: menuOpen && 'hidden',
        mt: '90px',
      }}>
        <Routes>
          <Route path='account' element={<MyAccountForm />} />
          <Route path='rgpd' element={<PersonnalData />} />
          <Route path='cgu' element={<CGU />} />
          {!profile.companyRef && <Route path='delete' element={<DeleteAccount />} />}
          <Route path='*' element={<Navigate replace to='account' />} />
        </Routes>
      </Box>
    </Page>
  )
}

export default ProfilePage
