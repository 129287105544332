import React from 'react'
import { Box, Typography } from '@mui/material'
import InvitationDisplay from '../teams/team/InvitationDisplay'
import UserTable from './UserTable'
import Page from '../_library/Page'
import useFirestore from '../../hooks/useFirestore'
import { COMPANY_COLLECTION } from '../../_constants/globals'


const AdminDashboardPage = () => {
  
  const companyHooks = useFirestore(COMPANY_COLLECTION)
  const company = companyHooks.getDocs()[0]
  
  return (
    <Page withTopBar>
      <Box maxWidth={1200} mx='auto'>
        <Box mb={3}>
          <Typography variant='h1' display='inline-block' my={2}>Admin</Typography>
          <InvitationDisplay amount={company?.licences || 0} />
        </Box>
        <UserTable />
      </Box>
    </Page>
  )
}

export default AdminDashboardPage
