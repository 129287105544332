import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ClickAwayListener, CircularProgress, Tooltip } from '@mui/material'
import { Close } from '@mui/icons-material'


export const DeleteButton = ({ deleteAction, className, title = 'Supprimer' }) => {
  
  const [activated, setActivated] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const action = () => {
    setSubmitted(true)
    deleteAction()
  }
  
  const style = useMemo(() => ({
    position: 'absolute',
    right: 2,
    border: 'solid 2px',
    borderColor: activated ? 'secondary.main' : 'white',
    color: activated ? 'secondary.main' : 'white',
    backgroundColor: activated && 'white',
    height: '30px',
    width: activated ? 'auto' : '30px',
    minWidth: 'initial',
    borderRadius: 15,
    py: 0,
    px: activated ? '10px' : 0,
    fontWeight: 600,
    zIndex: 1,
    '&:hover': {
      backgroundColor: activated ? 'secondary.main' : 'white',
      color: activated ? 'white' : 'secondary.main',
    },
    '& svg': {
      fontSize: '1rem',
    },
  }), [activated])

  return (
    <ClickAwayListener onClickAway={() => setActivated(false)}>
      <Tooltip title={title}>
        {submitted
          ? <CircularProgress className={className} color='inherit' size='1.5rem' sx={style} />
          : <Button
            onClick={activated ? action : () => setActivated(true)}
            className={className}
            sx={style}
          >
            {activated ? title : <Close />}
          </Button>
        }
      </Tooltip>
    </ClickAwayListener>
  )
}

DeleteButton.propTypes = {
  deleteAction: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
}

export default DeleteButton
