import React, { useMemo, useState } from 'react'
import { Grid, Typography, useTheme, useMediaQuery, Button, Box } from '@mui/material'
import DisplayTeamPanel from './DisplayTeamPanel'
import InvitationForm from './InvitationForm'
import Balloon from '../../cards/Balloon'
import { HOME, PRICING } from '../../../_constants/routes'
import TeamListIcon from '../../../assets/DIVERS/teamlist-icon.svg'
import InvitationDisplay from './InvitationDisplay'
import { useNavigate } from 'react-router-dom'
import Page from '../../_library/Page'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../hooks/useAuth'
import { COMPANY_COLLECTION } from '../../../_constants/globals'
import useFirestore from '../../../hooks/useFirestore'


const TeamPage = () => {
  console.log('TeamPage')
  const profile = useAuth().getProfile()
  const companyHooks = useFirestore(COMPANY_COLLECTION)
  const dbCompanies = companyHooks.getDocs()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()
  
  const [openTeam, setOpenTeam] = useState(false)
  
  const company = useMemo(() => dbCompanies ? dbCompanies[0] : null, [dbCompanies])
  
  if (!profile.companyRef)
    return (
      <Balloon preset='inviteTeamFreemium' onClick={() => navigate(HOME)} onCTA={() => navigate(PRICING)} styleTitle={{ paddingTop: 20, fontSize: '2rem' }} />
    )
  else return (
    <Page withTopBar>
      <Grid container height='100%'>
        {(isMdUp || !openTeam) && <Grid item xs={12} sm={7}>
          {company && <InvitationDisplay amount={company?.licences || 0} />}
          <Button onClick={() => setOpenTeam(true)} sx={{ display: { xs: 'block', md: 'none' } }}>
            <Box component='img' src={TeamListIcon} alt='Team List icon' sx={{ height: '60px', width: '60px' }} />
            <Box sx={{ fontWeight: 700, textAlign: 'center', color: 'primary.main' }}>TEAM</Box>
          </Button>
          <Box sx={{
            maxWidth: 900,
            my: { xs: 0, md: '5%' },
            mx: 'auto',
            p: '5%',
          }}>
            <Typography variant='h1' mb={{ xs: 2, md: 6 }}>{t('team.addMember')}</Typography>
            <Typography variant='body1' fontSize='1.5rem'>
              {t('team.inviteParagraph')}
            </Typography>
            <InvitationForm />
          </Box>
        </Grid>}
        {(isMdUp || openTeam) && <Grid item xs={12} sm={5} sx={{
          bgcolor: 'primary.main',
          p: { xs: 1, md: 3 },
          position: 'relative',
          overflowY: 'auto',
        }}>
          <Button onClick={() => setOpenTeam(false)} sx={{
            display: { xs: 'block', md: 'none' },
            borderRadius: '50%',
          }}>
            <Box sx={{
              backgroundColor: 'secondary.main',
              color: 'grey.100',
              fontWeight: 700,
              fontSize: '2rem',
              height: '60px',
              width: '60px',
              borderRadius: '50%',
              textAlign: 'center',
              lineHeight: 1.9,
              margin: 'auto',
            }}>+</Box>
            <Box sx={{ fontWeight: 700, textAlign: 'center', color: 'grey.100', textTransform: 'upperCase' }}>{t('common.add')}</Box>
          </Button>
          <DisplayTeamPanel />
        </Grid>}
      </Grid>
    </Page>
  )
}

export default TeamPage
