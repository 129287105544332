import React from 'react'
import PropTypes from 'prop-types'
import GreenCheckbox from '../_library/GreenCheckbox'
import { Clear } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'


export const CustomQuestion = ({ text, selected, selectHandler, deleteHandler }) =>
  <Box sx={{ mt: 2, display: 'flex', color: 'error.main' }}>
    <GreenCheckbox checked={selected} onChange={event => selectHandler(event.target.checked)} />
    <Clear onClick={deleteHandler} sx={{ cursor: 'pointer', mt: 1 }} />
    <Typography component='span' color='black'>{text}</Typography>
  </Box>

CustomQuestion.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  selectHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
}

export default CustomQuestion
