import React from 'react'
import { Grid } from '@mui/material'
import WelcomeComponent from './WelcomeComponent'
import SelectAvatarForm from './SelectAvatarForm'
import OnboardingEndScreen from './OnboardingEndScreen'
import Page from '../_library/Page'
import useAuth from '../../hooks/useAuth'


const SelectAvatarPage = () => {
  
  const profile = useAuth().getProfile()

  return (
    <Page withTopBar>
      <Grid container height='100%'>
        <Grid item xs={12} sm={7}>
          {profile.photoUrl || profile.photoUrlNotNow
            ? <OnboardingEndScreen />
            : <WelcomeComponent />
          }
        </Grid>
        <Grid item xs={12} sm={5} bgcolor='background.blue'>
          <SelectAvatarForm />
        </Grid>
      </Grid>
    </Page>
  )
}

export default SelectAvatarPage
