import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@mui/material'
import CTAButton from '../_library/FirstLevelButton'
import heartAnimation from '../../assets/ONBOARDING/heartAnimation.mp4'
import VideoSection from './VideoSection'
import Vimeo from 'react-vimeo'
import { useTranslation } from 'react-i18next'


const ExplainScreen = ({ goFore }) => {
  
  const { t } = useTranslation()
  
  return (
    <Grid container>
      <Grid item xs={12} sm={10} md={7}>
        <Box sx={{ py: 6, px: 1, maxWidth: '600px', m: 'auto' }}>
          <Typography variant='h3' component='h1' fontSize='1.8rem' fontWeight={600}>{t('onboarding.welcome-on-board')}</Typography>
          <Typography variant='h1' component='h2' mb={4}>{t('onboarding.hours')}</Typography>
          <Box sx={{ display: { sm: 'flex' } }}>
            <Vimeo videoId='489001368' autoplay />
            <Typography fontSize='1.2rem'>
              {t('onboarding.explain-paragraph')}
            </Typography>
          </Box>
          <CTAButton onClick={goFore} sx={{ mt: 4 }}>{t('onboarding.sure')}</CTAButton>
        </Box>
      </Grid>
      <VideoSection animation={heartAnimation} />
    </Grid>
  )
}

ExplainScreen.propTypes = {
  goFore: PropTypes.func.isRequired,
}

export default ExplainScreen
