import React from 'react'
import onboardingAnimation from '../../assets/DIVERS/Onboarding_animation.mp4'
import { Box } from '@mui/material'


const LoginAnimation = () => (
  <Box component='video' autoPlay='autoPlay' loop='loop' muted sx={{
    width: '100%',
    mt: 5,
    ml: 0,
  }}>
    <source src={onboardingAnimation} type='video/mp4' />
      Your browser does not support the video tag.
  </Box>
)

export default LoginAnimation
