import { combineReducers } from 'redux'
import dataReducer from './dataReducer'
import notificationReducer from './notificationReducer'
import profileSlice from '../slices/profileSlice'
import firestoreSlice from '../slices/firestoreSlice'


export default combineReducers({
  profile: profileSlice,
  data: dataReducer,
  firestore: firestoreSlice,
  notifications: notificationReducer,
})
