/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import { Button, FormControl, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import Loading from '../_library/Loading'
import MUIDataTable from 'mui-datatables'
import Avatar from '../_library/Avatar'
import UserTableToolbar from './UserTableToolbar'
import { getRoles, xssCheck } from '../../_helpers/helpers'
import { useTranslation } from 'react-i18next'
import editIcon from '../../assets/DIVERS/editIcon.png'
import { REGEX_MAIL } from '../../_constants/regex'
import { useNavigate } from 'react-router-dom'
import { TEAM } from '../../_constants/routes'
import { makeStyles } from '@mui/styles'
import { TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import ThirdLevelButton from '../_library/ThirdLevelButton'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


const HEADER_RADIUS = 40
const useStyles = makeStyles(theme => ({
  root: {
    color: 'black',
    backgroundColor: theme.palette.background.default,
    '& .MuiTable-root': {
      borderCollapse: 'separate',
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(1),
    },
    '& th.MuiTableCell-head': {
      backgroundColor: theme.palette.grey[150],
    },
    '& th.MuiTableCell-head:first-child': {
      borderTopLeftRadius: HEADER_RADIUS,
      borderBottomLeftRadius: HEADER_RADIUS,
    },
    '& th.MuiTableCell-head:last-child': {
      borderTopRightRadius: HEADER_RADIUS,
      borderBottomRightRadius: HEADER_RADIUS,
    },
  },
  teamLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  plusTeam: {
    color: theme.palette.grey[200],
    fontSize: '1rem',
  },
}))

const UserTable = () => {
  
  const classes = useStyles()
  const { t } = useTranslation()
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  const users = userHooks.getDocs()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const teams = teamHooks.getDocs()
  const navigate = useNavigate()

  const [editUserId, setEditUserId] = useState(null)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [editFirstname, setEditFirstname] = useState(null)
  const [editEmail, setEditEmail] = useState(null)
  const [editAccessLevel, setEditAccessLevel] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const user = users && editUserId && users.find(u => u.id === editUserId)
    setEditFirstname(user?.firstname || null)
    setEditEmail(user?.email || null)
    setEditAccessLevel(user?.accessLevel || null)
  }, [users, editUserId])

  const data = useMemo(() =>
    users?.map(val =>
      [
        val,
        { photoUrl: val.photoUrl, firstname: val.firstname },
        val.email,
        val.teamRefs?.map(ref => teams?.find(team => team.id === ref.id)),
        val.accessLevel,
        val,
        val.id,
      ],
    ), [users, teams])

  const handleSubmit = () => {
    if (!editEmail) setErrors(val => ({ ...val, email: 'Email field must be completed' }))
    else if (!xssCheck(editEmail) || !editEmail.match(REGEX_MAIL))
      setErrors(val => ({ ...val, email: 'L\'email doit être du type email@domain.ext' }))
    if (!editFirstname) setErrors(val => ({ ...val, firstname: 'Firstname field must be completed' }))
    else if (!xssCheck(editFirstname)) setErrors(val => ({ ...val, firstname: 'Le prénom n\'est pas valide' }))
    if (xssCheck(editEmail) && editEmail.match(REGEX_MAIL) && xssCheck(editFirstname)) {
      setSubmitted(true)
      userHooks.updateDoc(editUserId, {
        email: editEmail,
        firstname: editFirstname,
        accessLevel: editAccessLevel,
      }).then(() => {
        setSubmitted(false)
        setErrors({})
        return setEditUserId(null)
      })
    }
  }

  if (!users) return <Loading />
  else return (
    <MUIDataTable
      className={classes.root}
      data={data}
      columns={[
        { name: 'object', options: { filter: false, sort: false, display: 'excluded', print: false, searchable: false, download: false } },
        { name: 'MEMBRES', options: {
          sortCompare: order => ({ data: { firstname: firstname1 } }, { data: { firstname: firstname2 } }) =>
            order === 'asc'
              ? firstname1.localeCompare(firstname2)
              : firstname2.localeCompare(firstname1),
          customBodyRender: ({ photoUrl, firstname }, { rowData }) => // eslint-disable-line react/prop-types
            <div>
              <Avatar photoUrl={photoUrl} sx={{
                height: '50px',
                width: '50px',
                display: 'inline-block',
                verticalAlign: 'middle',
                mr: 1,
              }} />&nbsp;
              {editUserId === rowData[0].id
                ? <TextField
                  value={editFirstname || ''}
                  onChange={e => setEditFirstname(e.target.value)}
                  variant='outlined'
                  disabled={submitted}
                  error={!!errors.firstname}
                  helperText={errors.firstname}
                />
                : firstname
              }
            </div>,
        } },
        { name: 'E-MAIL', options: {
          customBodyRender: (email, { rowData }) => {
            if (editUserId === rowData[0].id)
              return <TextField
                value={editEmail || ''}
                onChange={e => setEditEmail(e.target.value)}
                variant='outlined'
                disabled={submitted}
                error={!!errors.email}
                helperText={errors.email}
              />
            else if (rowData[0].error) {
              return <Tooltip title={t('error.' + rowData[0].error)}><span style={{ color: 'red' }}>{email}</span></Tooltip>
            }
            else return email
          },
        } },
        { name: 'EQUIPES', options: { customBodyRender: (teams, { rowData }) => teams?.map((team, index) => {
          if (!team) return null
          else if (index < 2 || editUserId === rowData[0].id) return <div
            key={index}
            className={classes.teamLink}
            onClick={() => userHooks.updateDoc(profile.id, { currentTeamSelectedRef: teamHooks.getDocRef(team.id) }).then(() => navigate(TEAM))}
          >{team?.name}</div>
          else if (index === 2) return <div key={index} className={classes.plusTeam}>(+{teams.length - 2})</div>
          else return null
        }) } },
        { name: 'ROLE', options: { customBodyRender: (accessLevel, { rowData }) => editUserId === rowData[0].id
          ? <FormControl className={classes.formControl} variant='outlined' disabled={submitted} size='small'>
            <Select
              labelId='role-select-label'
              id='role-select'
              value={editAccessLevel || ''}
              onChange={e => setEditAccessLevel(e.target.value)}
            >
              {getRoles().map(role => profile.accessLevel >= role.accessLevel &&
                <MenuItem key={role.accessLevel} value={role.accessLevel}>{role.title}</MenuItem>,
              )}
            </Select>
          </FormControl>
          : getRoles().find(role => role.accessLevel === accessLevel)?.title,
        } },
        { name: 'STATUTS', options: { customBodyRender: user => {
          if (user.error) return <Tooltip title={user.error}><span>Erreur</span></Tooltip>
          else if (user.onboardingStatus) return t(`onboarding.status.${user.onboardingStatus}`)
          else return ''
        } } },
        { name: 'MODIFIER', options: { filter: false, sort: false, customBodyRender: (id, { rowData }) => editUserId === rowData[0].id
          ? <ThirdLevelButton onClick={handleSubmit} disabled={submitted}>Valider</ThirdLevelButton>
          : <Button onClick={() => setEditUserId(rowData[0].id)}><img src={editIcon} alt='editUser' /></Button>,
        } },
      ]}
      options={{
        responsive: 'simple',
        pagination: false,
        viewColumns: false,
        print: false,
        search: false,
        download: false,
        elevation: 0,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => setSelectedUsers(allRowsSelected.map(({ dataIndex }) => users[dataIndex])),
        selectToolbarPlacement: 'none',
        rowsSelected: selectedUsers.map(u => users.findIndex(user => user.id === u.id)),
        fixedHeader: false,
      }}
      components={{
        TableToolbar: () => <UserTableToolbar selectedUsers={selectedUsers} resetSelect={() => setSelectedUsers([])} />,
      }}
    />
  )
}

export default UserTable
