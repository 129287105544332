import React from 'react'
import { styled } from '@mui/material/styles'
import rgpd from '../../assets/DIVERS/rgpd.png'


const PREFIX = 'PersonalData'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
}

const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    width: '50%',
    marginLeft: '15%',
    marginBottom: '2rem',
    font: 'Raleway',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      marginLeft: '5%',
    },
  },

  [`& .${classes.title}`]: {
    marginTop: '3rem',
  },

  [`& .${classes.text}`]: {
    marginBottom: '1rem',
  },
}))

const PersonalData = () => (
  <Root className={classes.root}>
    <h2>Préambule</h2>
    <p>
        Le Règlement Général sur la Protection des données (RGPD) impose une information complète et précise. Tout au long de ce document,
        nous assurons donc une transparence suffisante pour que vous puissiez :
    </p>
    <ul>
      <li>connaître la raison de la collecte des différentes données</li>
      <li>comprendre le traitement qui sera fait de vos données</li>
      <li>assurer la maîtrise de vos données en facilitant l’exercice de vos droits.</li>
    </ul>

    <h2>Responsable de traitement</h2>
    <p>WAKE UP est le responsable de traitement, il est l’organisme qui détermine les finalités et les moyens du traitement de vos données.</p>
    <p>
        WAKE UP est une SAS au capital de 10 000 €, immatriculée au RCS de Bernay sous le 824 546 063 et dont le siège sociale se situe 12
        Chemin Vert, 27170 Beaumont Le Roger, Téléphone 01.76.35.02.24, bonjour@wake-up.io.
    </p>

    <h2>Délégué à la protection des données ou DPO</h2>
    <p>
        Le Délégué à la protection des données est Monsieur Sébastien SORS, Directeur Général de la SAS WAKE UP, sebastien(at)wake-up.io,
        12 chemin vert, 27170 Beaumont Le Roger, France, ci-après le « DPO ». C’est lui qui est le chef d’orchestre du traitement des données.
        Il contrôle le respect du RGPD. Il est également rappelé ici qu’il n’y a pas conflit d’intérêt quant à un rôle du DPO dans la
        détermination des finalités et des moyens du traitement.
    </p>

    <h2>Conformité RGPD</h2>
    <p>Afin de satisfaire à la loi française en matière de RGDP, nous allons détailler dans les prochains points les aspects suivants :</p>
    <ul>
      <li>Traitement licite, loyal et transparent des données</li>
      <li>Limitation des données</li>
      <li>Respect des données interdites</li>
      <li>Finalités du traitement</li>
      <li>Information des personnes et consentement</li>
      <li>Respect des droits</li>
      <li>Limitation de la durée de conservation des données</li>
      <li>Gestion des transferts de données</li>
      <li>Sécurité et confidentialité</li>
      <li>Tenue d’un registre des traitements</li>
    </ul>

    <h2>Traitement licite</h2>
    <p>
        Boussole est une plateforme en ligne (accessible à l’adresse pro.boussole.io) à destination des entreprises, ONG ou associations
        qui souhaitent améliorer la qualité des relations entre les personnes et renforcer l’alignement d’intérêt entre l’organisation
        (ci-après, “l’Organisation”) et les personnes (ci-après, les “Utilisateurs”).
    </p>
    <p>
        Pour ce faire, WAKE UP récolte à travers un test en 128 propositions les valeurs personnelles des Utilisateurs, les traite à
        travers des algorithmes et restitue aux Utilisateurs des résultats sous forme de textes personnalisés et de cartographies.
    </p>
    <img style={{ maxWidth: '100%' }} src={rgpd} alt='rectangle' />
    <p>
        Pour maintenir le dialogue, la personnalisation de l’expérience avec les Utilisateurs, et le fonctionnement collectif de la
        plateforme Boussole, WAKE UP doit pouvoir identifier les utilisateurs à l’aide d’un e-mail obligatoire. Selon la Loi, cet e-mail
        est considéré comme une donnée personnelle qu’il est important de protéger.
    </p>
    <p>
        La base légale du traitement des données personnelles est donc la bonne exécution du Service pour lequel le Client
        (Organisation ou personne physique) paye.
    </p>
    <h2>Limitation des données</h2>
    <p>Pour assurer le bon fonctionnement du Service, WAKE UP utilise les données personnelles suivantes pour chaque Utilisateur :</p>
    <ul>
      <li>l’e-mail</li>
      <li>le prénom</li>
      <li>le nom de l’entreprise</li>
      <li>l’adresse de facturation de l’entreprise</li>
      <li>la liste des personnes dans l’équipe</li>
      <li>le statut : manager / collaborateur</li>
      <li>la photo (optionnel)</li>
    </ul>
    <p>La photo de l’Utilisateur améliore l’expérience des utilisateurs mais n’est pas obligatoire pour le bon fonctionnement du Service.</p>
    <p>
        Les autres données que récupère WAKE UP n’entrent pas dans la catégorie de “donnée personnelle” puisqu’il est impossible
        d&apos;identifier (directement ou indirectement) la personne physique qui est derrière ces données :
    </p>
    <ul>
      <li>les réponses aux propositions des différents tests de valeurs personnelles</li>
      <li>le genre (pour la personnalisation des textes)</li>
      <li>le nombre d’invitations achetées dans Boussole</li>
      <li>la note d’évaluation du profil personnel</li>
      <li>le statut d’avancement dans le produit (nombre de cartes collectées etc.)</li>
    </ul>
    <p>WAKE UP limite strictement la collecte des données pour assurer le fonctionnement du Service et assurer le service client.</p>

    <h2>Respect des données interdites</h2>
    <p>Aucune donnée interdite au sens de l’Article 9 RGPD n’est collectée et/ou stockée.</p>
    <p>En l&apos;occurrence, la mesure des valeurs personnelles ne s’apparente en aucun cas avec une évaluation :</p>
    <ul>
      <li>de l’origine raciale ou ethnique</li>
      <li>de l’appartenance syndicale</li>
      <li>des options politiques, religieuses ou philosophiques</li>
      <li>des infractions ou condamnations</li>
      <li>de l’orientation et vie sexuelle</li>
      <li>des données de santé, biométriques ou génétiques</li>
      <li>des difficultés sociales, du numéro de sécurité sociale</li>
      <li>des données bancaires </li>
    </ul>

    <h2>Finalités du traitement</h2>
    <p>Le Service aide chaque Utilisateur à :</p>
    <ul>
      <li>Connaître ses talents et ses qualités en entreprise</li>
      <li>Connaître les talents et les qualités de ses coéquipiers</li>
      <li>Connaître les talents et les forces de l’équipe</li>
      <li>Connaître la culture de son équipe ou de son entreprise</li>
      <li>Apprendre à développer ses talents et ses qualités</li>
      <li>Apprendre à aider les autres à développer leurs talents et leurs qualités</li>
    </ul>

    <h2>Information des personnes et consentement</h2>
    <p>Les personnes concernées par la manipulation des données personnelles sont les suivantes :</p>
    <ul>
      <li>l’acheteur non Utilisateur</li>
      <li>l’acheteur Utilisateur</li>
      <li>l’Utilisateur invité par l’acheteur ou par un autre Utilisateur</li>
    </ul>
    <p>
        Elles sont informées de la collecte et du traitement des données personnelles dès le début de l’utilisation du Service via
        un lien (qui redirige vers le présent document) et une case à cocher obligatoire pour poursuivre l’utilisation du Service.
        La preuve du consentement est alors déterminée par la date d’inscription.
    </p>
    <p>
        Le consentement peut être retiré aussi facilement qu’il est donné. Pour ce faire, l’Utilisateur clique sur son avatar, puis dans
        la section Confidentialité il obtient un lien qui produit un mail automatique pour demander la suppression de ses données.
    </p>

    <h2>Respect des droits</h2>
    <p>Le Service permet de dresser des profils de personnes, de groupes et d’entreprises.</p>
    <p>
        Dans ce sens, il est encadré par les lignes directrices du G29 (groupe de travail qui réunit les autorités de contrôle des Etats
        Membres de l’Union Européenne).
    </p>
    <p>
        Ce profilage n’est toutefois pas associé à une prise de décision automatisée. C’est-à-dire qu’aucune machine ne va décider de
        quoi que ce soit à partir des profils individuels et/ou collectifs. D’après la CNIL, il ne porte donc pas atteinte aux droits
        et aux libertés des Utilisateurs.

    </p>
    <p>
        Les profils peuvent toutefois être modifiés dans une certaine mesure en s’adressant au service client dans la bulle de chat en
        bas à droite.
    </p>
    <p>
        Par ailleurs, chaque Utilisateur attribue une note de 1 à 5 à son profil personnel et peut ajouter une note écrite d’un maximum de
        250 caractères.
    </p>
    <p>
        Les autres Utilisateurs, membres de son équipe, sont les seuls à pouvoir accéder à son profil, à la note qui lui a été attribuée et
        au commentaire associé. Ce système permet à tout Utilisateur de qualifier la pertinence de son profil et compenser d’éventuels
        défauts de pertinence lors du traitement.
    </p>
    <p>
        Si la note attribuée au profil est inférieure ou égale à 2 sur 5, un message automatique prévient WAKE UP du défaut de pertinence.
        Les équipes de WAKE UP peuvent alors corriger manuellement les défauts de pertinence afin de garantir la qualité des informations
        qui sont partagées aux autres Utilisateurs.
    </p>
    <p>
        Le partage de son profil individuel avec les autres Utilisateurs ne se fait que si et seulement si l’Utilisateur attribue une note
        à son profil. L’utilisateur est informé de ce partage par une bulle informative avant la lecture de la carte qui résume son talent.
    </p>

    <h2>Limitation de conservation des données</h2>
    <p>Voici les limitations selon les cas :</p>
    <ul>
      <li>L’Utilisateur quitte l’entreprise : il peut supprimer ses données dans la section Confidentialité n’importe quand, avant ou
          après avoir quitté l’entreprise.</li>
      <li>Les données restent stockées tant que l’Utilisateur n’est pas désinscrit du Service.</li>
      <li>Si l’Utilisateur est désinscrit, seul le mail / prénom / coordonnées entreprise / nombre d’invitations achetées sont conservés
          pendant 3 ans afin d’assurer le suivi comptable</li>
    </ul>

    <h2>Gestion des transferts de données</h2>
    <p>Le Service fonctionne avec différents outils à travers lesquels les données personnelles transitent :</p>
    <ul>
      <li>Firebase : est la base de données cloud mère qui se trouve en Europe (Allemagne). C’est elle qui centralise toutes les données.</li>
      <li>Quickbooks : est le logiciel cloud comptable. Les données sont stockées hors UE.</li>
      <li>Sendinblue : est le logiciel cloud de messagerie et de suivi Utilisateur, la plateforme est elle-même compliant avec le RGPD et les données sont stockées en France.</li>
      <li>Google Workspace : est le service e-mail de WAKE UP si l’utilisateur écrit un e-mail au service client à l’adresse bonjour@wake-up.io. Les données sont stockées aux USA.</li></ul>

    <h2>Sécurité et confidentialité</h2>
    <p>Notre politique de sécurité surveille l’intégrité, la confidentialité, l’accessibilité et l’authenticité des données.</p>
    <p>
        Cette politique inclut un processus continu d’évaluation des risques et de mise en œuvre des actions pour réduire au maximum toute
        faille qui pourrait compromettre la confidentialité des données personnelles. Nous avons mis en œuvre une Politique de Sécurité de
        l’Information à cet effet.
    </p>

    <h2>Tenue d’un registre des traitements</h2>
    <p>
        L’ensemble des flux de données sont formalisés dans un registre de traitement mis à jour régulièrement permettant de cartographier
        et remettre en question la pertinence des données collectées au sens du RGPD.
    </p>
  </Root>
)

export default PersonalData
