import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dropPhoto from '../../assets/DIVERS/drop-photo.png'
import miniloader from '../../assets/DIVERS/miniloader.gif'
import { Box, Button, ButtonGroup, IconButton } from '@mui/material'
import { USER_COLLECTION } from '../../_constants/globals'
import getCroppedImg from '../../_helpers/imageHelper'
import Cropper from 'react-easy-crop'
import { Cancel, Check, CropRotate, Edit } from '@mui/icons-material'
import FirstLevelButton from '../_library/FirstLevelButton'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


const imgStyle = {
  display: 'block',
  margin: '0 auto',
  maxWidth: '100%',
}
export const SelectAvatarForm = () => {
  
  const { t } = useTranslation()
  const profile = useAuth().getProfile()
  const auth = useAuth()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [rawImage, setRawImage] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [areaPixels, setAreaPixels] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  
  const imgUrl = useMemo(() => rawImage && URL.createObjectURL(rawImage), [rawImage])
  
  const handleSubmit = () => {
    setSubmitted(true)
    getCroppedImg(imgUrl, areaPixels, rotation)
      .then(img => auth.uploadProfilePicture(img, profile.id))
      .then(() => setRawImage(null))
      .finally(() => setSubmitted(false))
  }
  
  return (<>
    <Box sx={{
      mt: '15%',
      width: '100%',
      minHeight: '262px',
      position: 'relative',
    }}>
      {submitted
        ? <Box component='img' src={miniloader} alt='miniloader' sx={{ ...imgStyle, borderRadius: '50%' }} />
        : imgUrl
          ? <Cropper
            image={imgUrl}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={(_, area) => setAreaPixels(area)}
            onZoomChange={setZoom}
            showGrid={false}
            cropShape='round'
          />
          : profile.photoUrl
            ? <Box component='img' src={profile.photoUrl} alt='avatar' sx={imgStyle} />
            : <Box component='img' src={dropPhoto} alt='drop' sx={imgStyle} />
      }
      {imgUrl && !submitted && <Button
        onClick={() => setRotation(prev => (prev - 90) % 360)}
        sx={{ color: 'grey.100' }}
      ><CropRotate /></Button>}
    </Box>
    {imgUrl
      ? <ButtonGroup sx={{ display: 'block', width: 'fit-content', mx: 'auto' }}>
        <IconButton onClick={() => setRawImage(null)}><Cancel /></IconButton>
        <IconButton component='label' ><Edit /><input hidden accept='image/*' type='file' onChange={e => setRawImage(e.target.files[0])} /></IconButton>
        <IconButton onClick={handleSubmit}><Check /></IconButton>
      </ButtonGroup>
      : <FirstLevelButton component='label' id='editAvatarButton' sx={{ mx: 'auto', display: 'block', width: 'fit-content', mt: 2 }}>
        {t(profile.photoUrl ? 'onboarding.editImage' : 'onboarding.selectImage')}
        <input hidden accept='image/*' type='file' onChange={e => setRawImage(e.target.files[0])} />
      </FirstLevelButton>
    }
    {!profile.photoUrl && !profile.photoUrlNotNow && !imgUrl &&
      <Button
        id='doItLaterBtn'
        variant='text'
        onClick={() => userHooks.updateDoc(profile.id, { photoUrlNotNow: true })}
        sx={{
          mt: 2,
          color: 'white',
          opacity: .5,
          width: '100%',
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        {t('onboarding.doItLater')}
      </Button>
    }
  </>)
}

export default SelectAvatarForm
