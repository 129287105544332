import React, { useState } from 'react'
import { FormControlLabel, TextField, Typography, Checkbox, FormHelperText, FormControl, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CTAButton from '../_library/FirstLevelButton'
import '@stripe/stripe-js'
import { PAYMENT_SUMMARY } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import { UNIT_PRICE } from '../../_helpers/pricingHelper'


const PriceCalculatorForm = () => {
  
  const { t } = useTranslation()
  const navigate = useNavigate()
  
  const [licenses, setLicenses] = useState(1)
  const [acceptedConditions, setAcceptedConditions] = useState(false)
  const [error, setError] = useState(false)
  
  const handleSubmit = e => {
    e.preventDefault()
    return acceptedConditions
      ? navigate(PAYMENT_SUMMARY.replace(':number', licenses))
      : setError(true)
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <Box id='calculateUnitPrice' sx={{ display: 'flex', my: 3 }}>
        <TextField
          InputProps={{ sx: { fontWeight: 700, fontSize: '3rem', width: 'min-content' } }}
          inputProps={{ sx: { p: 0, height: 'initial' } }}
          name='licences'
          type='number'
          label={t('pricing.voucher-num')}
          value={licenses}
          onChange={e => e.target.value > 0 && setLicenses(e.target.value)}
        />
        <Typography color='primary.main' fontSize='3rem' mx={2} fontWeight={700}>=</Typography>
        <Typography variant='subtitle1' color='#2e936c' fontSize='3rem' display='inline-block' fontWeight={700} sx={{
          '&:after': {
            content: '"€ HT"',
            fontSize: '1.5rem',
            verticalAlign: 'super',
          },
        }}>
          {licenses * UNIT_PRICE}
        </Typography>
        <Typography variant='body2' color='grey.300' fontWeight={700} ml={2}>{UNIT_PRICE}€ / personne</Typography>
      </Box>
      <Typography variant='body2' color='grey.200' fontWeight={700} maxWidth={{ xs: '100%', sm: '80%' }} mb={2}>
        Les licences Boussole sont actives durant 1 an à compter de la date d&apos;activation
      </Typography>
      <FormControl required error={error}>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptedConditions}
              onChange={e => setAcceptedConditions(e.target.checked)}
              color='primary'
            />
          }
          label={<Typography variant='body2'>J&apos;accepte les <a href='https://boussole.io/cgv/' target='_blank' style={{ color: 'inherit', textDecoration: 'none', fontWeight: 'bold' }} rel='noopener noreferrer'>conditions générales de vente</a></Typography>}
        />
        {error && <FormHelperText>Pensez à lire et accepter les conditions générales pour continuer.</FormHelperText>}
      </FormControl>
      <CTAButton disabled={licenses <= 0} type='submit' sx={{ mt: 2 }}>Acheter les licences</CTAButton>
    </form>
  )
}

export default PriceCalculatorForm
