import { fromPairs, mapValues } from 'lodash'

export const xssCheck = string =>
  string && !['&', '<', '>', '"', "'", "/", '\\', '{', '}', '(', ')'].some(element => string.includes(element))

/**
 * Parses a string of type url params into an object
 *
 * @param url
 *
 * @example
 * input : http://site.web?mode=resetPassword&oobCode=Ae5ee&apiKey=AIzaSyC5U&lang=en
 * output : {
 *   apiKey: "AIzaSy",
 *   lang: "en",
 *   mode: "resetPassword",
 *   oobCode: "Ae5ee"
 * }
 */
export const parseParams = url => {
  if (!url) return {}
  const encoded = url.split('?')[1]
  const strings = encoded.split('&')
  const pairs = strings.map(param => param.split('='))
  const encodedParams = fromPairs(pairs)
  return mapValues(encodedParams, decodeURIComponent)
}

export const getRoles = () => [
  { title: 'Collaborateur', accessLevel: 25 },
  { title: 'Manager', accessLevel: 50 },
  { title: 'Admin', accessLevel: 75 },
  { title: 'SuperAdmin', accessLevel: 100 },
]
