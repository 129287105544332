export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const WARNING = 'WARNING'
export const INFO = 'INFO'
export const LOADING = 'LOADING'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const LISTEN_PROFILE = 'LISTEN_PROFILE'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'
export const SIGNIN_EMAIL_SUCCESS = 'SIGNIN_EMAIL_SUCCESS'
export const SIGNIN_EMAIL_ERROR = 'SIGNIN_EMAIL_ERROR'
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS'
export const CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR'
export const RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS'
export const RESET_PASSWORD_EMAIL_ERROR = 'RESET_PASSWORD_EMAIL_ERROR'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const HIDE_NOTIFICATIONS = 'HIDE_NOTIFICATIONS'
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const UPLOAD_ERROR = 'UPLOAD_ERROR'
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'

export const LISTEN_COLLECTION = 'LISTEN_COLLECTION'
export const LISTEN_ANSWERS = 'LISTEN_ANSWERS'
export const LISTEN_CARD_TEMPLATES = 'LISTEN_CARD_TEMPLATES'
export const LISTEN_CARDS = 'LISTEN_CARDS'
export const LISTEN_COMPANY_DOC = 'LISTEN_COMPANY_DOC'
export const LISTEN_EMAILS = 'LISTEN_EMAILS'
export const LISTEN_ONEONE_TOPICS = 'LISTEN_ONEONE_TOPICS'
export const LISTEN_PAYMENTS = 'LISTEN_PAYMENTS'
export const LISTEN_PROPOSALS = 'LISTEN_PROPOSALS'
export const LISTEN_QUESTIONS = 'LISTEN_QUESTIONS'
export const LISTEN_SEQUENCES = 'LISTEN_SEQUENCES'
export const LISTEN_TALENTS = 'LISTEN_TALENTS'
export const LISTEN_TEAMS = 'LISTEN_TEAMS'
export const LISTEN_USERS = 'LISTEN_USERS'

export const INVITE_MEMBER_SUCCESS = 'INVITE_MEMBER_SUCCESS'
export const INVITE_MEMBER_ERROR = 'INVITE_MEMBER_ERROR'

export const ADD_TO_TEAM_SUCCESS = 'ADD_TO_TEAM_SUCCESS'
export const ADD_TO_TEAM_ERROR = 'ADD_TO_TEAM_ERROR'
export const SEND_RESPONSES = 'SEND_RESPONSES'
export const LOADED_USER_CARDS = 'LOADED_USER_CARDS'
export const LOADED_TEAM_CARDS = 'LOADED_TEAM_CARDS'
export const FETCH_TALENT = 'FETCH_TALENT'
export const ANONYMOUS_CONVERT_SUCCESS = 'ANONYMOUS_CONVERT_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const CREATE_ORDER_AND_SESSION_SUCCESS = 'CREATE_ORDER_AND_SESSION_SUCCESS'


export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS'
export const CREATE_TEAM_ERROR = 'CREATE_TEAM_ERROR'
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS'
export const UPDATE_TEAM_ERROR = 'UPDATE_TEAM_ERROR'

export const UPDATE_COMPANY = 'UPDATE_COMPANY'

export const OPEN_CHAT = 'OPEN_CHAT'
export const SET_CONSULTANT_MODE = 'SET_CONSULTANT_MODE'
export const SELECT_USER_ID = 'SELECT_USER_ID'

export const RESET_CACHE = 'RESET_CACHE'
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const NOTIFICATION = 'NOTIFICATION'
