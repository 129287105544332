import React, { useEffect, useMemo, useState } from 'react'
import { Typography, TextField, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { HOME } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import CTAButton from '../_library/FirstLevelButton'
import { REGEX_MAIL } from '../../_constants/regex'
import useAuth from '../../hooks/useAuth'


const LoginForm = () => {
  
  const { t } = useTranslation()
  const auth = useAuth()
  const profile = auth.getProfile()
  const navigate = useNavigate()
  
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [isSubmitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})
  const [resetPasswordSubmitted, setResetPasswordSubmitted] = useState(false)
  
  useEffect(() => {
    if (email && email.length > 0 && !email.match(REGEX_MAIL)) setErrors(prev => ({ ...prev, email: t('login.error.invalid-email') }))
    if (password && password.length > 0 && password.length < 6) setErrors(prev => ({ ...prev, password: t('login.error.password-length') }))
    return () => setErrors({})
  }, [email, password, t])
  
  useEffect(() => {
    if (profile.id) navigate(HOME)
  }, [profile, navigate])
  
  const enableSubmit = useMemo(() => email && password && Object.keys(errors).length === 0 && !isSubmitted, [email, password, errors, isSubmitted])
  
  const handleSubmitSignIn = e => {
    e.preventDefault()
    setSubmitted(true)
    auth.signIn({ email, password })
      .then(() => navigate(HOME))
      .finally(() => setSubmitted(false))
  }
  
  const resetPassword = () => {
    if (!email) setErrors(prev => ({ ...prev, email: t('login.error.invalid-email') }))
    else {
      setResetPasswordSubmitted(true)
      auth.sendResetPasswordEmail(email)
        .finally(() => setResetPasswordSubmitted(false))
    }
  }
  
  return (
    <form onSubmit={handleSubmitSignIn} noValidate id='loginForm'>
      <TextField
        name='email'
        type='email'
        label={t('common.email')}
        value={email || ''}
        onChange={e => setEmail(e.target.value)}
        fullWidth
        color='primary'
        autoFocus
        disabled={isSubmitted}
        error={!!errors.email}
        helperText={errors.email}
        sx={{ mb: 2 }}
      />
      <TextField
        name='password'
        type='password'
        label={t('common.password')}
        value={password || ''}
        onChange={e => setPassword(e.target.value)}
        fullWidth
        color='primary'
        disabled={isSubmitted}
        error={!!errors.password}
        helperText={errors.password}
        sx={{ mb: 2 }}
      />
      <CTAButton type='submit' disabled={!enableSubmit} sx={{
        mt: 4,
        mr: 0,
        mb: 1,
        ml: { md: 1, lg: 0 },
        width: { md: '90%', lg: 'initial' },
        height: { md: '3.5rem' },
      }}>
        {t('login.identify')}
      </CTAButton>
      <Typography fontSize='.8rem' mb={1}>
        {t('login.lost-password')}&nbsp;
        <Link onClick={resetPassword} color='secondary.main' sx={resetPasswordSubmitted ? { color: 'grey.700', cursor: 'default' } : {}}>
          {t('login.reset-password')}
        </Link>
      </Typography>
    </form>
  )
}

export default LoginForm
