import { confirmPasswordReset, deleteUser, EmailAuthProvider, getRedirectResult, linkWithCredential, onAuthStateChanged,
  SAMLAuthProvider, signInWithEmailAndPassword, signInWithRedirect, signOut as apiSignOut, updatePassword } from 'firebase/auth'
import { addDoc, collection, deleteField, doc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore'
import { EMAIL_COLLECTION, EMAIL_RE_INVITE, PROFILE_STORAGE_REF, USER_COLLECTION } from '../_constants/globals'
import { auth, db, functions, listenerRefs, storage } from '../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../store/slices/profileSlice'
import { mapValues } from 'lodash'
import { resetLocalStorage } from '../_helpers/localStorageHelper'
import { ERROR, LOGIN_ERROR, PASSWORD_RESET_ERROR, PASSWORD_RESET_SUCCESS, RESET_PASSWORD_EMAIL_ERROR, RESET_PASSWORD_EMAIL_SUCCESS, SUCCESS,
  UPLOAD_COMPLETE, UPLOAD_ERROR, UPLOAD_PROGRESS } from '../store/types'
import { httpsCallable } from 'firebase/functions'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { useMemo } from 'react'
import { deSerialize } from '../_helpers/firestoreHelper'


const useAuth = () => {
  const dispatch = useDispatch()
  const serializedProfile = useSelector(state => state.profile?.data)
  
  const deserializedProfile = useMemo(() => deSerialize(serializedProfile), [serializedProfile])
  
  const listenProfile = () =>
    onAuthStateChanged(auth, authUser => setTimeout(() => {
      if (authUser) {
        const collectionListener = onSnapshot(doc(db, USER_COLLECTION, authUser.uid), async dbUser => {
          const profile = {
            id: dbUser.id,
            ...dbUser.data(),
            isAnonymous: auth.currentUser.isAnonymous,
            emailVerified: auth.currentUser.emailVerified,
            isLoaded: true,
            token: await auth.currentUser.getIdTokenResult(),
            isEmpty: false,
          }
          console.info('profile', profile)
          return dispatch(actions.success(profile))
        })
        listenerRefs.auth = { unsubscribe: collectionListener }
      }
      else dispatch(actions.success({ isEmpty: true, isLoaded: true }))
    }, 1000))
  
  const getProfile = () => deserializedProfile
  
  const signIn = ({ email, password }) =>
    signInWithEmailAndPassword(auth, email, password)
      .catch(() => dispatch({ type: LOGIN_ERROR }))
  
  const signOut = () => {
    mapValues(listenerRefs, ({ unsubscribe }) => unsubscribe())
    resetLocalStorage()
    console.info('signOut')
    return apiSignOut(auth)
  }
  
  const sendResetPasswordEmail = email =>
    httpsCallable(functions, 'createResetPasswordEmail')({
      email,
      origin: process.env.BASE_URL,
      _VERBOSE: process.env.NODE_ENV !== 'production',
    })
      .then(() => dispatch({ type: RESET_PASSWORD_EMAIL_SUCCESS }))
      .catch(() => dispatch({ type: RESET_PASSWORD_EMAIL_ERROR }))
  
  /**
   * Update password for the logged in user
   *
   * @param {string} password
   * @returns function(*=, *, {getFirebase: *}): Promise<void | *>
   */
  const updatePasswordAction = password => {
    console.log('updatePasswordAction', password)
    return updatePassword(auth.currentUser, password)
      .then(() => updateDoc(doc(db, USER_COLLECTION, auth.currentUser.uid), {
        temporaryPassword: deleteField(),
      }))
      .catch(err => {
        if (process.env.NODE_ENV !== 'production') console.error(err)
        return dispatch({ type: LOGIN_ERROR })
      })
  }
  
  
  const uploadProfilePicture = (file, uid) =>
    new Promise(resolve => {
      const extension = file.type.split('/').pop()
      const filename = `${uid}-${(new Date()).getMilliseconds()}.${extension}`
      const storageRef = ref(storage, `${PROFILE_STORAGE_REF}/${filename}}`)
      const uploadTask = uploadBytesResumable(storageRef, file)
      uploadTask.on('state_changed',
        snapshot => dispatch({ type: UPLOAD_PROGRESS, progress: 100 * snapshot.bytesTransferred / snapshot.totalBytes }),
        error => dispatch({ type: UPLOAD_ERROR, error }),
        () => {
          dispatch({ type: UPLOAD_COMPLETE })
          resolve()
        },
      )
    })
  
  /**
   * Resend an invite email to an already created user
   * @param {string} email
   */
  const resendInviteEmailAction = email =>
    addDoc(collection(db, EMAIL_COLLECTION), {
      _createdAtTime: serverTimestamp(),
      type: EMAIL_RE_INVITE,
      to: email,
      origin: window.location.origin,
    })
      .then(() => dispatch({ type: SUCCESS, payload: 'L\'invitation a bien été envoyée' }))
      .catch(err => {
        console.error(err)
        return dispatch({ type: ERROR, payload: 'L\'invitation n\'a pas été envoyée' })
      })
  
  const inviteUserAction = (user, notification) =>
    httpsCallable(functions, 'inviteUser')(user)
      .then(({ data }) => {
        if (notification)
          dispatch({
            type: SUCCESS,
            payload: `notifications.users.create.success`,
          })
        return data
      })
      .catch(err => {
        console.error(err)
        if (!notification) return // eslint-disable-line no-useless-return
        else if (err.message === 'No more licences') {
          dispatch({
            type: ERROR,
            payload: `notifications.users.create.licences`,
          })
        }
        else
          dispatch({
            type: ERROR,
            payload: `notifications.create.error`,
          })
      })
  
  /**
   * Signs in with SAML
   */
  const samlSignInAction = providerId => {
    const provider = new SAMLAuthProvider(providerId) // eslint-disable-line
    return signInWithRedirect(auth, provider)
  }
  
  const samlCallbackAction = async targetEmail =>
    new Promise((resolve, reject) => {
      getRedirectResult(auth)
        .then(res => {
          if (!res) return resolve(false)
          console.info('SAML IDP Response', res)
          if (res.user.email === targetEmail) {
            return updateDoc(doc(db, USER_COLLECTION, res.user.uid), { temporaryPassword: false })
              .then(() => resolve(true))
              .catch(() => deleteUser(auth.currentUser).then(() => {
                dispatch({ type: LOGIN_ERROR })
                return reject(new Error('not found dbUser ' + res.user.uid))
              }))
          }
          else return deleteUser(auth.currentUser).then(() => {
            dispatch({ type: LOGIN_ERROR })
            return reject(new Error('email mismatch'))
          })
        })
    })
  
  const resetPasswordAction = (oobCode, password) =>
    confirmPasswordReset(auth, oobCode, password)
      .then(() => dispatch({ type: PASSWORD_RESET_SUCCESS }))
      .catch(err => dispatch({ type: PASSWORD_RESET_ERROR, err }))
  
  /**
   * Create an authenticated user based on form inputs and connected user
   *
   * @param {string} email
   * @param {string} password
   * @param {string} firstname
   * @param {boolean} newsletter
   */
  const convertAnonymousUserAction = (email, password, firstname, newsletter) =>
    linkWithCredential(auth.currentUser, EmailAuthProvider.credential(email, password))
      .then(() => updateDoc(doc(db, USER_COLLECTION, auth.currentUser.uid), {
        firstname,
        email,
        newsletter,
        _createdAtTime: serverTimestamp(),
        _origin: window.location.origin,
      }))
  
  return { listenProfile, getProfile, signIn, signOut, sendResetPasswordEmail, updatePasswordAction, uploadProfilePicture,
    resendInviteEmailAction, inviteUserAction, samlSignInAction, samlCallbackAction, resetPasswordAction, convertAnonymousUserAction }
}

export default useAuth
