import React from 'react'
import PropTypes from 'prop-types'
import avatarTeamMember from '../../assets/DIVERS/avatar_team_member.svg'
import { Box } from '@mui/material'


const Avatar = ({ photoUrl, sx }) => (
  <Box sx={{
    overflow: 'hidden',
    border: '3px solid',
    borderRadius: '40px',
    backgroundColor: 'primary.main',
    borderColor: 'grey.100',
    width: '80px',
    height: '80px',
    zIndex: 1,
    '& img': {
      objectFit: 'cover',
      height: '100%',
    },
    ...sx,
  }}>
    {photoUrl
      ? <Box sx={{
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        backgroundImage: `url('${photoUrl}')`,
      }} />
      : <img src={avatarTeamMember} alt='undefined' />
    }
  </Box>
)

Avatar.propTypes = {
  photoUrl: PropTypes.string,
  sx: PropTypes.object,
}

export default Avatar
