import React, { useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import TeamChip from './TeamChip'
import TeamChipEdit from './TeamChipEdit'
import { ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_MANAGER, TEAM_COLLECTION } from '../../_constants/globals'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


export const DisplayTeamsPanel = () => {
  
  const profile = useAuth().getProfile()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHooks.getDocs()
  const { t } = useTranslation()

  const [edit, setEdit]= useState(false)
  
  const teams = useMemo(() => dbTeams?.filter(t => t.managerRefs?.some(ref => ref.id === profile.id)), [dbTeams, profile.id])

  return (
    <Box sx={{ color: 'white', m: '5vh auto 20px', maxWidth: '600px' }}>
      <Typography variant='h1' fontSize={{ xs: '2.2rem', lg: '2.5rem' }} fontWeight={600} color='rgba(255, 255, 255, .5)'>
        {profile.accessLevel >= ACCESS_LEVEL_ADMIN
          ? t('team.teams-of') + profile.company.name
          : t('team.your-teams')
        }
      </Typography>
      {teams?.map(team => edit
        ? <TeamChipEdit team={team} key={team.id} />
        : <TeamChip team={team} key={team.id} />,
      )}
      {teams && profile.accessLevel >= ACCESS_LEVEL_MANAGER &&
      <Button variant='text' onClick={() => setEdit(prevState => !prevState)} sx={{ float: 'right', mt: 2 }}>
        {edit ? t('common.save') : t('team.edit')}
      </Button>
      }
    </Box>
  )
}

export default DisplayTeamsPanel
