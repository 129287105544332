import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Card, CardContent, CardHeader, IconButton, Link, Typography } from '@mui/material'
import founders from '../../assets/DIVERS/founders.png'
import logo from '../../assets/DIVERS/logo-wakeup.png'
import { Close } from '@mui/icons-material'


const PremiumAdCard = ({ onClose }) => {
  
  const [display, setDisplay] = useState(false)

  useEffect(() => {
    setTimeout(() => setDisplay(true), 4000)
  }, [])

  return display && (
    <Card sx={{
      position: 'fixed',
      bottom: 0,
      right: { md: '40px', sm: 0 },
      backgroundColor: 'primary.dark',
      width: { md: '358px', sm: '100%' },
      maxWidth: '100%',
      zIndex: 2,
    }}>
      {onClose && <CardHeader action={<IconButton onClick={onClose} size='large'><Close /></IconButton>} sx={{ position: 'absolute', py: 1, px: 0 }} />}
      <CardContent>
        <Box sx={{ width: 'fit-content', position: 'relative', m: 'auto' }}>
          <img src={founders} alt='founders' />
          <Box component='img' src={logo} alt='logo wakeup' sx={{ position: 'absolute', transform: 'rotate(13deg)', right: '-40%', width: '64px' }} />
        </Box>
        <Typography variant='h1' fontSize='2rem' textAlign='center' sx={{ mb: 1, color: 'tertiary.lighter' }}>Avancez avec nous</Typography>
        <Typography sx={{ display: 'block', color: 'tertiary.lighter', textAlign: 'center' }}>
          Suivez notre formation en management pour faire grandir en autonomie votre équipe.
        </Typography>
        <Link
          sx={{ display: 'block', color: 'tertiary.lighter', textAlign: 'center', mt: 2 }}
          href='https://www.wake-up.io/programme-manager-sherpa?&utm_campaign=pop'
          target='_blank'
        >Découvrir le programme &gt;</Link>
      </CardContent>
    </Card>
  )
}

PremiumAdCard.propTypes = {
  onClose: PropTypes.func,
}

export default PremiumAdCard
