import {
  ADD_TO_TEAM_ERROR, ADD_TO_TEAM_SUCCESS, HIDE_NOTIFICATIONS, CREATE_ORDER_AND_SESSION_SUCCESS, UPDATE_COMPANY,
  OPEN_CHAT, RESET_CACHE, UPDATE_USER_ERROR, UPDATE_USER_SUCCESS, CREATE_TEAM_SUCCESS, CREATE_TEAM_ERROR,
  UPDATE_TEAM_SUCCESS, UPDATE_TEAM_ERROR, SET_CONSULTANT_MODE, SELECT_USER_ID,
} from '../types'

const initState = {
  dataError: null,
  updateUserSuccess: null,
  consultantMode: false,
}

const dataReducer = (state = initState, action) => {
  switch(action.type){
    case ADD_TO_TEAM_SUCCESS:
      console.log('add to team success')
      return {
        ...state,
        dataError: null,
      }
    
    case ADD_TO_TEAM_ERROR:
      console.log('add to team error')
      console.log(action.err)
      return {
        ...state,
        dataError: action.err,
      }
    
    case HIDE_NOTIFICATIONS:
      return { ...state,
        dataError: null,
        createOrderAndSessionResult: null,
      }
    
    case RESET_CACHE:
      return {
        dataError: null,
        createOrderAndSessionResult: null,
      }
    
    case CREATE_ORDER_AND_SESSION_SUCCESS:
      return {
        ...state,
        createOrderAndSessionResult: action.payload,
      }
    
    case UPDATE_COMPANY:
      return {
        ...state,
        vouchers: action.vouchers,
      }
    
    case OPEN_CHAT:
      return {
        ...state,
        openChat: action.payload,
      }
    
    case UPDATE_USER_ERROR:
      console.error('update user error', action.payload)
      return {
        ...state,
        updateUserSuccess: null,
        dataError: action.payload,
      }
    
    case UPDATE_USER_SUCCESS:
      console.log('update user success')
      return {
        ...state,
        updateUserSuccess: true,
        dataError: null,
      }
    
    case CREATE_TEAM_ERROR:
      console.error('create team error', action.payload)
      return {
        ...state,
        createTeamSuccess: null,
        dataError: action.payload,
      }
    
    case CREATE_TEAM_SUCCESS:
      console.log('create team success')
      return {
        ...state,
        createTeamSuccess: true,
        dataError: null,
      }
    
    case UPDATE_TEAM_ERROR:
      console.error('update team error', action.payload)
      return {
        ...state,
        updateTeamSuccess: null,
        dataError: action.payload,
      }
    
    case UPDATE_TEAM_SUCCESS:
      console.log('update team success')
      return {
        ...state,
        updateTeamSuccess: true,
        dataError: null,
      }
    
    case SET_CONSULTANT_MODE:
      console.log('set consultant mode', action.payload)
      return {
        ...state,
        consultantMode: action.payload,
      }
    
    case SELECT_USER_ID:
      console.log('select user id', action.payload)
      return {
        ...state,
        selectedUserId: action.payload,
      }
    
    default:
      return state
  }
}

export default dataReducer
