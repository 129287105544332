import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { HOME } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import { REGEX_PWD } from '../../_constants/regex'
import CTAButton from '../_library/FirstLevelButton'
import useAuth from '../../hooks/useAuth'


export const TmpPasswordForm = ({ email, temporaryPassword }) => {
  
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = useAuth()
  const profile = useAuth().getProfile()
  
  const [password, setPassword] = useState(null)
  const [isSubmitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})
  
  useEffect(() => {
    auth.signIn({ email, password: temporaryPassword })
  }, [])
  
  useEffect(() => {
    if (password && !password.match(REGEX_PWD))
      setErrors(prev => ({ ...prev, password: t('login.error.password-length') }))
    else setErrors({})
    return () => setErrors({})
  }, [password])
  
  const enableBtn = useMemo(() => !!profile.id && password && Object.keys(errors).length === 0 && !isSubmitted, [profile.id, password, errors, isSubmitted])
  
  const handleSubmitSignIn = e => {
    e.preventDefault()
    setSubmitted(true)
    auth.updatePasswordAction(password)
      .then(() => navigate(HOME))
      .catch(() => setSubmitted(false))
  }
  
  return (
    <form onSubmit={handleSubmitSignIn} noValidate>
      <TextField
        name='email'
        type='email'
        label={t('common.professional-email')}
        value={email}
        fullWidth
        color='primary'
        disabled
        sx={{ mb: 2 }}
      />
      <TextField
        name='temporaryPassword'
        type='text'
        label={t('login.temporary-password.label')}
        value={temporaryPassword}
        fullWidth
        color='primary'
        disabled
        sx={{ mb: 2 }}
      />
      <TextField
        name='password'
        type='password'
        label={t('login.temporary-password.new-password')}
        value={password || ''}
        onChange={e => setPassword(e.target.value)}
        fullWidth
        color='primary'
        disabled={isSubmitted}
        error={!!errors.password}
        helperText={errors.password}
        autoFocus
        sx={{ mb: 2 }}
      />
      <CTAButton type='submit' disabled={!enableBtn} sx={{ mt: 4, width: { xs: '100%', md: 'inherit' } }}>
        {t('login.temporary-password.validate')}
      </CTAButton>
    </form>
  )
}

TmpPasswordForm.propTypes = {
  email: PropTypes.string.isRequired,
  temporaryPassword: PropTypes.string.isRequired,
}

export default TmpPasswordForm
