import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Grid, Typography } from '@mui/material'
import Lock from '../../assets/CARDS/icon-lock.png'
import Card from './Card'
import { flatten } from 'lodash'


const CardSection = ({ title, children, locked }) => {

  children = flatten(children) // Cannot use js flat method as it is not implemented in node for unit tests

  const ghosts = useMemo(() => {
    const childrenLength = children.filter(element => element?.props?.title?.length > 0).length
    return childrenLength >= 4 ? 0 : 4 - childrenLength
  }, [children])

  return (
    <Box sx={{ maxWidth: { xs: '800px', md: '1200px' }, mx: 'auto', position: 'relative' }}>
      <Box sx={{ position: 'relative' }}>
        <Divider sx={{
          width: '-webkit-fill-available',
          position: 'absolute',
          margin: '.9rem 2rem',
          borderColor: 'grey.200',
          zIndex: -1,
        }} />
        <Typography
          variant='h2'
          fontWeight={800}
          fontSize={20}
          color={locked ? 'grey.200' : 'primary.main'}
          bgcolor='background.default'
          my={{ xs: 2, sm: 6 }}
          mx='auto'
          px={2}
          width='fit-content'
        >{title}</Typography>
        {locked && <Box component='img' src={Lock} alt='lock' sx={{
          position: 'absolute',
          top: '-30px',
          left: '50%',
          width: '24px',
          marginLeft: '-12px',
        }} />}
      </Box>
      <Grid container overflow='auto' flexWrap='nowrap'>
        {children}
        {Array.from(Array(ghosts)).map((val, key) => <Card key={key} type='ghost' />)}
      </Grid>
    </Box>
  )
}

CardSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.array,
  locked: PropTypes.bool,
}

export default CardSection
