import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Link, Typography } from '@mui/material'
import CTAButton from '../_library/FirstLevelButton'
import treeAnimation from '../../assets/ONBOARDING/treeAnimation.mp4'
import ArrowbackIosIcon from '@mui/icons-material/ArrowBackIos'
import { CGU, RGPD } from '../../_constants/routes'
import VideoSection from './VideoSection'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'


const LetsGoScreen = ({ goBack, goFore })  => {
  
  const navigate = useNavigate()
  const { t } = useTranslation()
  
  const [acceptedConditions, setAcceptedConditions] = useState(false)
  const [error, setError] = useState(false)
  
  return (
    <Grid container>
      <Grid item xs={12} sm={10} md={7}>
        <Box sx={{ py: 6, px: 1, maxWidth: '600px', m: 'auto' }}>
          <Typography variant='h3' component='h1' fontSize='1.8rem' fontWeight={600}>{t('onboarding.start-with')}</Typography>
          <Typography variant='h1' component='h2' mb={4}>{t('onboarding.discover')}</Typography>
          <Typography fontSize='1.2rem'>
            <Trans i18nKey='onboarding.know-yourself'>
              Avant de bien connaître les autres, <b>il faut bien se connaître soi-même</b>.
            </Trans>
          </Typography>
          <Typography fontSize='1.2rem'>
            <Trans i18nKey='onboarding.timing'>
              Vous vous apprêtez à démarrer dans Boussole, avec 128 questions pour découvrir <b>vos talents</b>.
              Rassurez-vous, cela ne devrait pas vous prendre plus de <b>15 minutes</b>.
            </Trans>
          </Typography>
          <Typography fontSize='1.2rem'>
            <Trans i18nKey='onboarding.spontaneous'>
              Allez-y avec <b>le plus de spontanéité possible</b> et projetez-vous dans un <b>contexte de vie personnelle</b>. Nous nous chargerons de traduire vos réponses dans le monde professionnel.
            </Trans>
          </Typography>
          <FormControl required error={error}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptedConditions}
                  onChange={e => setAcceptedConditions(e.target.checked)}
                  name='conditionCheck'
                  color='primary'
                />
              }
              label={
                <Typography component='span' pt={0} fontWeight='normal' >
                  <Trans i18nKey='onboarding.cgu'>
                    En cochant cette case vous acceptez les <Link onClick={() => navigate(CGU)} fontWeight={600}>conditions générales d&apos;utilisation.</Link> Consultez notre <Link onClick={() => navigate(RGPD)} fontWeight={600}>politique RGPD.</Link>
                  </Trans>
                </Typography>
              }
            />
            {error && <FormHelperText>{t('onboarding.cgu-error')}</FormHelperText>}
          </FormControl><br />
          <CTAButton onClick={() => !acceptedConditions ? setError(true) : goFore()} sx={{ mt: 4 }}>
            {t('onboarding.start')}
          </CTAButton>
          <Box fontSize='.9rem' my={1}>
            <Typography component='span' fontSize='.9rem'>{t('onboarding.more-info')}</Typography>
            <Link onClick={() => window.open('https://boussole.io/faq/', '_blank')} fontWeight={600}>
              {t('onboarding.faq')}
            </Link>
          </Box>
          <Button onClick={goBack} sx={{ display: 'flex', textTransform: 'none', fontSize: '1.1rem', mt: 2, fontWeight: 600, color: 'primary.main' }}>
            <ArrowbackIosIcon />{t('onboarding.back')}
          </Button>
        </Box>
      </Grid>
      <VideoSection animation={treeAnimation} />
    </Grid>
  )
}

LetsGoScreen.propTypes = {
  goFore: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
}

export default LetsGoScreen
